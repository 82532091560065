import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import Loader from "./Loader";
import { Helmet } from "react-helmet";
const TitleRewriter = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  const outputRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Rewrite this title again: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });

      const userMessage = { role: "user", content: prompt };

      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph ;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
      // setInput("");
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    Title Rewriter - Rewrite Your Titles Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/TitleRewriter"
  />
  <meta
    name="description"
    content="Rewrite your titles online. Perfect for improving your content titles and making them more engaging."
  />
  <meta
    name="keywords"
    content="title rewriter, rewrite titles, title generator, content title improvement, title enhancement, catchy title rewriting, engaging titles, title optimization, online title rewriter, title suggestion tool, creative title writing, unique title improvements, title crafting, effective title rewriting, title formulation, blog title improvement, article title rewriter, compelling titles, title modification, attention-grabbing titles, SEO title rewriting, title creativity, generate engaging titles, title brainstorming, headline rewriter, title revision, effective title suggestions, title enhancement tool, interactive title rewriter, title inspiration, user-friendly title tool, catchy title generator, blog post title improvement, optimized titles, writing compelling titles, title analysis tool, title writing strategies, write better titles, content title generator, quick title rewriter, title variation, title creativity techniques, title effectiveness analysis, title writing for SEO, generate unique titles, informative titles, descriptive titles, headline improvement, title comparison, title writing best practices, unique title creation, title structure improvement, marketing title generator, content-focused title writing, engaging headline generation, title customization, strategic title rewriting, trending title ideas, title optimization strategies, brainstorm new titles, effective headline writing, title alternatives, effective content titles, action-oriented titles, quality title writing, fast title generation, user-friendly title generator, creative title revision, attention-grabbing headlines, rewrite for clarity, innovative title suggestions,
     title writing assistance, unique content titles, title enhancement strategies, engaging title phrases, title crafting techniques, persuasive title writing, title effectiveness, content title brainstorming, rewriting for impact, fresh title ideas, title improvement strategies, title formulation techniques, effective titles for marketing, effective rephrasing, dynamic title rewriting, thematic titles, relevant title improvements, clever title rephrasing, efficient title writing, responsive title writing, impactful titles, title effectiveness analysis, title writing for bloggers, title improvement analysis, content marketing titles, title writing workshop, create catchy titles, title refinement, title creation strategies, online title generation, brainstorming title ideas, creative title suggestions, rewrite with style, title variation techniques, title customization for SEO, compelling headline crafting, unique title rewriter, title writing trends, clear and concise titles, impactful title rewriting"
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Title rewriter</h1>
        <p className="mt_5">Rewrite your title</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Rewriting Title</h4>
              <p>What's your previous title you want to rewrite?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your title here"
                  rows="14"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled" ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">AI Output</h4>
                  <p>Use the generated content as you please</p>
                </div>
              </div>
              <div className="form-group">
                <div   className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                          <div className="loader-container">
                            <Loader />
                          </div>
                        ) : (
                          messages.length > 0 &&
                          currentIndex !== -1 && (
                            <div className="p-3">
                              {messages[currentIndex].content}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button
                    className="buttonDownload"
                    onClick={handleDownload}
                    disabled={messages.length === 0}
                  >
                    Download
                  </button>
                <button className="Btn" onClick={handleCopy}>
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="tool-detail mt-5">
  This tool helps you enhance or rephrase your existing titles to make them more engaging and impactful. Whether you want to improve the clarity, SEO, or appeal of your title, simply enter your current title, and the tool will generate rewritten suggestions to better suit your content.
</p>
<div className="row align-items-center small_gutter">
  <div className="col-lg-5 mt_30">
    <h3 className="sub_title">STEP BY STEP</h3>
    <h2 className="title">How To Rewrite Your Title</h2>
    <p className="mt_25">Follow the steps below</p>
    <div className="step_item_box pt_10">
      <div className="step_item mt_30">
        <h4>Step 1</h4>
        <p className="mt_5">
          Enter the current title you want to improve in the provided input field.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 2</h4>
        <p className="mt_5">
          Click the "Rewrite Title" button to receive rephrased and enhanced versions of your title.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 3</h4>
        <p className="mt_5">
          Review the rewritten titles and select the one that best improves clarity, SEO, or audience engagement.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 4</h4>
        <p className="mt_5">
          Use the improved title for your content and repeat the process if you're looking for more suggestions.
        </p>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default TitleRewriter;
