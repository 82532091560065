import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
 
const messageStyle = {
  padding: "20px",
  borderRadius: "10px",
  maxWidth: "100%",
  marginBottom: "10px",
  alignSelf: "flex-start",
  overflow:'auto'
};

const Rephraser = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const outputRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
 const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/8000`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Rephrase this content: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
      if (window.innerWidth <= 600) {
        const generatedOutput = document.getElementById("generated-output");
        generatedOutput.scrollIntoView({ behavior: "smooth" });
      }
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(6); // Reset paragraphs to default value
    setEducationLevel("Formal"); // Reset education level to default value
  };  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Rephraser - Rephrase Sentences and Text Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/Rephraser" />
  <meta
    name="description"
    content="Easily rephrase your sentences and text online. Perfect for improving readability, avoiding plagiarism, and enhancing clarity in writing."
  />
  <meta
    name="keywords"
    content="rephraser, sentence rephraser, rephrase text, rewrite sentences, online rephraser tool, text rephraser, paraphrase tool, online paraphrasing tool, rewording tool, text rewriting, sentence rewording, improve writing, plagiarism avoidance, enhance text clarity, rephrase sentences, write better, writing improvement, content rephrasing, academic rephraser, paraphrase sentences, text optimization, writing assistant, content enhancement, paraphrasing sentences, online rewriting tool, express ideas differently, clarity in writing, creative rewriting, concise writing, effective communication, editing tool, sentence restructuring, articulate writing, improved readability, text adjustment, rewrite paragraphs, online text rewriter, writing software, sentence reformulation, enhanced expression, original content creation, rephrasing strategies, rewriting assistance, enhance written content, text transformation, writing efficiency, user-friendly rephrasing, automated rephrasing, rewrite for clarity, rephrase generator, rephrase online, rephrase sentences easily, text clarity improvement, rephrase anything, original writing tool, sentence simplification, rephrasing effectiveness, online content adjustment, articulate ideas, content reorganization, enhanced writing quality, seamless rephrasing, writing clarity, adjust wording, adapt sentences, comprehensive rephrasing, effective text alteration, online content creator, content optimization tool, rephrase for students, rephrase for professionals, easy rephrasing, simple text adjustment, rewording sentences, effective writing tool, rephrase for better understanding, content creativity tool, user-friendly rephrasing tool, accurate rephrasing, adapt written content, text improvement tool."
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Text Rephraser</h1>
        <p className="mt_5">Rephrase your texts</p>
      </div>
      <div className="summarizer container" style={{ minHeight: "406px", display: "block" }}>
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Rephrase</h4>
              <p>What's your content you want to rephrase?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/8000
                  </span>
                </div>
                <textarea
                  ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Article you want to rephrase"
                  rows="14"
                  cols="100"
                  maxLength="8000"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled" ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Rephrased text</h4>
                  <p>Here is your Rephrased text!</p>
                </div>
              </div>
              <div className="form-group">
                <div className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                    <div className="loader-container">
                      <Loader />
                    </div>
                  ) : (messages.length > 0 && currentIndex !== -1 && (
                          <div style={messageStyle}>
                            {messages[currentIndex].content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button className="buttonDownload" onClick={handleDownload}
                    disabled={messages.length === 0}>Download</button>
                <button className="Btn" onClick={handleCopy}>
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to rephrase your text for improved clarity and originality. Simply input your text, and the tool will generate a rephrased version for you.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Use the Text Rephraser</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Enter the text you want to rephrase in the provided text area.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            (Optional) Specify any particular style or tone you want the rephrased text to have.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Rephrase" button to generate the rephrased version of your text.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Review the rephrased text and make any necessary adjustments before using it.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default Rephraser;
