import React, { useState, useEffect } from "react";
import { PDFDocument, rgb } from "pdf-lib";
import Loader from "./Loader1";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
const PdfPageNumberWriter = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isConverting, setIsConverting] = useState(false);
  const [pageNumberStart, setPageNumberStart] = useState(1);
  const [modifiedPdfBlob, setModifiedPdfBlob] = useState(null);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const onFileChange = (e) => {
    setLoading(true);
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setShowDownloadButton(false);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const handlePageNumberChange = (e) => {
    setPageNumberStart(parseInt(e.target.value));
  };
  const assignPageNumbers = async () => {
    if (!file) return;
    try {
      setIsConverting(true);
      const existingPdfBytes = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes, {
        ignoreEncryption: true,
      });
      const pages = pdfDoc.getPages();
      pages.forEach((page, index) => {
        page.drawText(`Page ${pageNumberStart + index}`, {
          x: 50,
          y: 50,
          size: 12,
          color: rgb(0, 0, 0),
        });
      });
      const modifiedPdfBytes = await pdfDoc.save();
      const modifiedBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      setModifiedPdfBlob(modifiedBlob);
      setShowDownloadButton(true);
    } catch (error) {
      console.error("Error:", error);
    }
    setTimeout(() => {
      setIsConverting(false);
    }, 2000);
  };

  const handleDownload = () => {
    if (modifiedPdfBlob) {
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(modifiedPdfBlob);
      downloadLink.download = "modified_pdf.pdf";
      downloadLink.click();
    }
  };
  const handleReset = () => {
    setFile(null);
    setModifiedPdfBlob(null);
  };
  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>
    Page Number Writer on PDF - Add Page Numbers to PDF Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/PdfTools/PageNumberWriter"
  />
  <meta
    name="description"
    content="Easily add page numbers to your PDF documents online. Ideal for organizing reports, presentations, and eBooks."
  />
  <meta
    name="keywords"
    content="add page numbers to pdf, pdf page number writer, insert page numbers in pdf, pdf numbering tool, number pdf pages online, page numbering in PDF, online page number tool, PDF page numbering, insert pagination in PDF, number pages in PDF, add pagination to PDF, PDF page numbering software, edit PDF page numbers, add footer page numbers PDF, number pages in PDF documents, online PDF page number insertion, page number generator for PDF, PDF numbering online, automate PDF page numbers, easy page numbering PDF, customize PDF page numbers, free PDF page number writer, add header page numbers PDF, bulk page number insertion PDF, organize PDF with page numbers, sequential page numbering PDF, PDF page number alignment, online PDF tools, PDF numbering service, create numbered PDF, add page count to PDF, page number insertion tool, PDF tools online, document page numbering, PDF editing tools, PDF with page numbers, online document management, quick page number tool, professional PDF page numbering, add page numbers for eBooks, user-friendly PDF numbering, PDF pagination online, add page numbers for presentations, manage PDF document pages, online pagination service, easily number PDF pages, number each page of PDF, customizable PDF numbering options, add automatic page numbers PDF, sequential numbering for PDF documents, edit PDF file page numbers, PDF page number customization, digital document page numbering, online PDF manipulation tools, add page numbers with ease, free online PDF page number tool, simple PDF page number editor, efficient PDF page number writer, enhance PDF readability with page numbers, generate numbered PDF documents, streamlined PDF page numbering process, create organized PDF files, structured PDF document management."
  />
</Helmet>

      <div className="text-center">
        <h1 className="title_lg">Page number writer on PDF</h1>
        <p className="mt_5">Assign page numbers to PDF</p>
      </div>
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-xl-10">
            <div className="uploader-container uploader-container--images">
              {loading ? (
                <Loader />
              ) : !file ? (
                <form className="file-upload-form">
                  <label htmlFor="file" className="file-upload-label">
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                      </svg>
                      <p>Drag and Drop</p>
                      <p>or</p>
                      <span className="browse-button">Browse file</span>
                    </div>
                    <input
                      id="file"
                      type="file"
                      accept=".pdf"
                      onChange={onFileChange}
                    />
                  </label>
                </form>
              ) : isConverting ? (
                <Loader />
              ) : (
                <>
                <div
                      style={{
                        textDecoration: "Underline",
                        fontWeight: "bold",
                      }}
                      className="my-1 filename"
                    >
                      {file && file.name}
                    </div>
                  <div className="input-container">
                    <input
                      id="input"
                      required=""
                      type="number"
                      value={pageNumberStart}
                      onChange={handlePageNumberChange}
                      min={1}
                      className="form-control text-input"
                    />
                    <label for="input" className="label">
                      Start Page Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <button
                    className="normal-button"
                    onClick={assignPageNumbers}
                    disabled={!file}
                  >
                    Assign Page Numbers
                  </button>
                  {showDownloadButton && (
                    <button
                      className="buttonDownload my-1"
                      onClick={handleDownload}
                    >
                      Download Modified PDF
                    </button>
                  )}
                  <button
                      className="Reset-button my-1"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to add page numbers to your PDF files easily. Upload your PDF, specify the position of the page numbers, and click "Add Page Numbers" to save your updated document.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Add Page Numbers to Your PDF</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Upload the PDF file you want to add page numbers to.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Choose the position where you want the page numbers to appear (e.g., top right, bottom center).
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click "Add Page Numbers" to apply the changes to your PDF.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Download the updated PDF file with the added page numbers.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default PdfPageNumberWriter;
