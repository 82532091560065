import React, { useState, useRef, useEffect } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker.entry";
import { useLocation } from "react-router-dom"; 
import JSZip from "jszip";
import Loader from "./Loader1";
import { Helmet } from "react-helmet";

const PDFToImageConverter = ({ imageFormat }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const location = useLocation();
  
  const supportedFormats = {
    png: "PNG",
    jpeg: "JPEG",
    jpg: "JPG",
    webp: "WEBP",
  };

  const formatUpperCase = supportedFormats[imageFormat] || "PNG"; 

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "application/pdf") {
      setFileName(selectedFile.name);
      setIsLoading(true); 
      await convertPdfToImages(selectedFile);
      setTimeout(() => {
        setIsLoading(false); 
      }, 2000);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const convertPdfToImages = async (pdfFile) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const pdfData = new Uint8Array(fileReader.result);
      const pdfDoc = await pdfjsLib.getDocument({ data: pdfData }).promise;
      const images = [];
      for (let i = 1; i <= pdfDoc.numPages; i++) {
        const page = await pdfDoc.getPage(i);
        const viewport = page.getViewport({ scale: 2 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        await page.render({ canvasContext: context, viewport }).promise;
        images.push(canvas.toDataURL(`image/${imageFormat}`)); // Convert to the selected format
      }
      setImageUrls(images);
    };

    fileReader.readAsArrayBuffer(pdfFile);
  };

  const downloadImage = (url, index) => {
    const a = document.createElement("a");
    a.href = url;
    a.download = `page-${index + 1}.${imageFormat}`; // Use selected format
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const clearInputs = () => {
    setImageUrls([]);
    setFileName("");
  };

  const downloadAllImages = async () => {
    const zip = new JSZip();
    const folder = zip.folder("pdf_images");

    imageUrls.forEach((url, index) => {
      const base64Data = url.split(',')[1];
      folder.file(`page-${index + 1}.${imageFormat}`, base64Data, { base64: true });
    });

    const content = await zip.generateAsync({ type: "blob" });
    const a = document.createElement("a");
    a.href = URL.createObjectURL(content);
    a.download = "pdf_images.zip";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>PDF to {formatUpperCase} Converter - Convert PDF Pages to {formatUpperCase} Online</title>
        <link rel="canonical" href={`http://toolsbag.io/PDFTools/pdfto${imageFormat}`} />
        <meta
          name="description"
          content={`Easily convert PDF pages to ${formatUpperCase} images online. Perfect for sharing, editing, or archiving PDF content as ${formatUpperCase} image files. Quick and simple to use.`}
        />
        <meta
          name="keywords"
          content={`PDF to ${formatUpperCase}, convert PDF to ${formatUpperCase}, PDF to ${formatUpperCase}, online PDF converter, convert PDF to ${formatUpperCase}, PDF image converter, PDF to Image, convert PDF to Image, PDF converter, convert PDF, PDF to JPG, PDF to PNG, PDF to GIF, PDF to TIFF, PDF to BMP, online PDF converter, free PDF converter, PDF to image online, convert PDF pages to image, extract images from PDF, PDF image conversion, best PDF converter, PDF to photo, PDF image extractor, PDF file to image, convert PDF fast, convert PDF for free, PDF to picture, export PDF to image, online PDF to image tool, PDF converter tool, PDF image maker, convert PDF to image free, PDF image online tool, PDF to JPG free, PDF to PNG free, PDF to GIF free, PDF to BMP free, PDF to TIFF free, high quality PDF converter, PDF to high res image, PDF to image download, download PDF image, convert PDF into image, convert PDF for web, share PDF as image,
           PDF to image software, fast PDF to image, online PDF image converter, free online PDF converter, best PDF to image, convert PDF into pictures, create images from PDF, export PDF as JPG, export PDF as PNG, export PDF as GIF, export PDF as BMP, export PDF as TIFF, convert PDF pages into image, split PDF into images, transform PDF to image, save PDF as image, PDF to graphic, convert PDF document to image, PDF to high-quality JPG, PDF to high-quality PNG, PDF to high-quality GIF, PDF to high-quality BMP, PDF to high-quality TIFF, PDF to image converter online, convert PDF instantly, convert PDF for social media, export PDF as picture, create picture from PDF, image from PDF online, convert PDF to HD image, convert PDF to web image, compress PDF to image, reduce PDF size to image, resize PDF image, PDF page to image, PDF to raster image, convert scanned PDF to image, split PDF into individual images, PDF to pixel image, batch convert PDF to image, convert PDF with transparent background, print PDF to image, save as image PDF converter, portable PDF to image, convert PDF for free online, easy PDF to image tool, quick PDF to image, PDF to digital image, best free PDF to image, best online PDF converter, convert multi-page PDF to image, PDF to single image, merge PDF to image, PDF to image cloud tool, online free PDF to image, secure PDF converter, convert encrypted PDF to image, unlock PDF to image, drag and drop PDF to image, batch PDF to JPG, batch PDF to PNG, batch PDF to GIF, batch PDF to TIFF, batch PDF to BMP, PDF to animated GIF, convert password protected PDF to image, convert PDF on mobile, mobile PDF to image, mobile-friendly PDF to image, PDF to image for Windows, PDF to image for Mac, PDF to image for iPhone,
            PDF to image for Android, PDF image converter, free image converter, convert PDF file to JPG, convert PDF file to PNG, convert PDF file to GIF, convert PDF file to BMP, convert PDF file to TIFF, convert PDF page to JPG, convert PDF page to PNG, convert PDF page to GIF, convert PDF page to BMP, convert PDF page to TIFF, PDF page as image, PDF to JPG high resolution, convert PDF to lossless image, PDF to transparent PNG, PDF to vector image, PDF image export, PDF image generation, convert PDF to multiple images, PDF document to images, convert entire PDF to image, PDF to HD image converter, split PDF into image files, split multi-page PDF to images, individual PDF pages to image, convert PDF graphics, convert PDF vector graphics, extract image from PDF, convert PDF charts to image, convert PDF tables to image, export PDF charts to image, export PDF tables to image, professional PDF to image, PDF to creative images, PDF to branding image, PDF to business image, convert PDF for design, PDF to image for presentations, PDF to visual content, convert PDF for email, convert PDF to image for website, convert PDF to image for social media sharing.`}
        />
      </Helmet>
      <div className="container">
        <div className="text-center">
          <h1 className="title_lg">PDF to {formatUpperCase} Converter</h1>
          <p className="mt_5">Convert your PDF to {formatUpperCase} images</p>
        </div>
        {fileName ? (
          <strong>
            <p className="filename">{fileName}</p>
          </strong>
        ) : (
          <div className="uploader-container uploader-container--images">
            <form className="file-upload-form">
              <label htmlFor="file" className="file-upload-label">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button">Browse file</span>
                </div>
                <input
                  type="file"
                  id="file"
                  accept=".pdf"
                  className="mt-2 w-75"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
              </label>
            </form>
          </div>
        )}
        {isLoading ? (
          <Loader />
        ) : (
          imageUrls.length > 0 && (
            <div className="mt-4">
              <div className="row">
                {imageUrls.map((url, index) => (
                  <div key={index} className="col-md-4 mb-4 text-center">
                    <img
                      src={url}
                      alt={`Page ${index + 1}`}
                      className="img-fluid mb-2 image-container1"
                      style={{ maxWidth: "80%" }}
                    />
                    <button
                      className="buttonDownload"
                      onClick={() => downloadImage(url, index)}
                    >
                      Download Page {index + 1}
                    </button>
                  </div>
                ))}
              </div>
              <div className="text-center mt-4">
                <button onClick={downloadAllImages} className="ml-2 buttonDownload">
                  Download All
                </button>
                <button className="Reset-button" onClick={clearInputs}>
                  Clear
                </button>
              </div>
            </div>
          )
        )}
      </div>
      <div className="container my-5">
        <p>
          This tool allows you to easily convert PDF pages into image files. Simply upload your PDF below, and after converting the pages, you can download them as images in {formatUpperCase} format. Perfect for sharing, editing, or archiving PDF content in image formats like {formatUpperCase}.
        </p>
        <div className="row align-items-center small_gutter">
          <div className="col-lg-5 mt_30">
            <h3 className="sub_title">STEP BY STEP</h3>
            <h2 className="title">How To Convert PDF to {formatUpperCase}</h2>
            <p className="mt_25">Follow along with the steps below</p>
            <div className="stepbox mt_15">
              <h3 className="steps">Step 1</h3>
              <p>Upload the PDF file by clicking on the "Browse file" button.</p>
            </div>
            <div className="stepbox mt_15">
              <h3 className="steps">Step 2</h3>
              <p>
                Once uploaded, the pages of the PDF will automatically convert to {formatUpperCase} images.
              </p>
            </div>
            <div className="stepbox mt_15">
              <h3 className="steps">Step 3</h3>
              <p>Download individual pages or download all as a .zip file.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFToImageConverter;
