import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
const messageStyle = {
   
  padding: "10px",
  borderRadius: "10px",
  maxWidth: "70%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const SentenceCompleter = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const outputRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Complete the sentence where incomplete sentence is: "${input}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
    setEducationLevel("Formal"); 
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>Sentence Completer - Complete Your Half Sentences Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/SentenceCompleter" />
  <meta
    name="description"
    content="Complete your half sentences online. Perfect for filling in missing ideas and structuring sentences."
  />
  <meta
    name="keywords"
    content="sentence completer, complete sentences, sentence finisher, sentence structure tool, online sentence completion, finish incomplete sentences, writing assistance, sentence construction aid, enhance writing, improve sentence structure, complete your thoughts, text enhancement tool, sentence generator, writing tool, sentence improvement, articulate ideas, coherent sentence creation, grammar tool, online writing help, phrase completer, content creation, writing support, enhance clarity, online text tool, completion tool for sentences, express your ideas, structured writing, refine your sentences, writing perfection, online grammar assistant, sentence clarity, creative writing tool, fill in the blanks, sentence elaborator, sentence synthesizer, complete your ideas, writing prompts, improve expression, enhance communication, sentence organization, articulate thoughts, complete partial sentences."
  />
</Helmet>



    <div className="text-center">
        <h1 className="title_lg">Sentence Completer</h1>
        <p className="mt_5">Complete your incomplete sentences</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Sentence</h4>
              <p>What's your incomplete sentence?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder=""
                  rows="15"
                  cols="100"
                  maxLength="100"
                  id="prompt"
                  className="form-control"
                ></textarea>
              </div>
              
              
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled"  ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Remaining part</h4>
                  <p>Here is the remmaining part of sentence!</p>
                </div>
              </div>
              <div className="form-group">
                <div data-v-4e037fd2="" className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          data-original-title="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                    <div className="loader-container">
                      <Loader />
                    </div>
                  ) : (messages.length > 0 && currentIndex !== -1 && (
                          <div style={messageStyle}>
                            {messages[currentIndex].content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button className="buttonDownload my-" onClick={handleDownload}
                    disabled={messages.length === 0}>Download</button>
                <button className="Btn" onClick={handleCopy}>
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>

        </div>
        <p className="tool-detail mt-5">
  This tool helps you complete your sentences by suggesting potential ways to finish them based on the text you've entered. Whether you're stuck on how to conclude your sentence or want creative suggestions, simply start typing your sentence and see the completions generated for you.
</p>
<div className="row align-items-center small_gutter">
  <div className="col-lg-5 mt_30">
    <h3 className="sub_title">STEP BY STEP</h3>
    <h2 className="title">How To Complete Your Sentences</h2>
    <p className="mt_25">Follow the steps below</p>
    <div className="step_item_box pt_10">
      <div className="step_item mt_30">
        <h4>Step 1</h4>
        <p className="mt_5">
          Enter the beginning of your sentence in the provided input field.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 2</h4>
        <p className="mt_5">
          Click the "Complete Sentence" button to generate possible sentence completions.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 3</h4>
        <p className="mt_5">
          Review the suggestions and choose the one that fits best, or modify it further to suit your needs.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 4</h4>
        <p className="mt_5">
          Use the completed sentence as needed and generate more if you're looking for additional ideas!
        </p>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default SentenceCompleter;
