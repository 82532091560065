import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';
  
const CsvToJsonConverter = () => {
  const [csvData, setCsvData] = useState('');
  const [jsonData, setJsonData] = useState('');
  const [csvFile, setCsvFile] = useState(null);
const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleCsvChange = (event) => {
    setCsvData(event.target.value);
    setCsvFile(null); 
  };
  const handleCsvFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setCsvFile(selectedFile);
    setCsvData(''); 
  };
  const convertCsvToJson = () => {
    try {
      const dataToParse = csvFile ? csvFile : csvData;
      Papa.parse(dataToParse, {
        header: true, // Treat the first row as headers
        complete: (result) => {
          const jsonResult = JSON.stringify(result.data, null, 2);
          setJsonData(jsonResult);
        },
        error: (error) => {
          console.error('Error parsing CSV:', error);
          alert('Error parsing CSV. Please check the CSV format.');
        },
      });
    } catch (error) {
      console.error('Error converting CSV to JSON:', error);
      alert('Error converting CSV to JSON. Please check the CSV format.');
    }
  };
  const downloadJson = () => {
    if (jsonData) {
      const blob = new Blob([jsonData], { type: 'application/json' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'converted_data.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (<>
  <Helmet>
  <meta charSet="utf-8" />
  <title>CSV to JSON Converter - Convert CSV to JSON Online</title>
  <link rel="canonical" href="http://toolsbag.io/PdfTools/CsvToJsonConverter" />
  <meta
    name="description"
    content="Easily convert your CSV files into JSON format online. Ideal for data interchange, APIs, and structured data transformation."
  />
  <meta
    name="keywords"
    content="csv to json, convert csv to json, csv to json converter, csv to json online, transform csv to json, online csv tools, csv to json formatting, csv data to json, csv json conversion, json from csv, csv json converter tool, csv to json service, structured data conversion, data interchange format, online data converter, free csv to json converter, fast csv to json, csv data transformation, json format tool, csv file to json, json data from csv, csv to structured json, data transformation tools, csv to json for APIs, convert csv files, csv to json integration, csv parsing to json, json file generation from csv, csv data parsing, convert csv to structured format, json data structure, online data processing tools, csv manipulation, csv data handling, csv to json API, json output from csv, csv to json schema, transform csv into json format, convert CSV records to JSON, CSV to JSON format converter, JSON formatting tools, online data transformation, data format conversion tools, csv data exporter, json conversion services, csv row to json object, data conversion online, csv dataset to json, json formatting from csv, online csv manipulation tools, csv to json lightweight tool, CSV to JSON online utility, fast data transformation, json structure generator,
    csv database to json, csv to json automation, transform csv into web-ready json, csv file parsing to json, data management tools, csv transformation services, JSON generator from CSV, csv to json converter application, json creation from csv, csv file format to json, csv to json document converter, csv records to json format, csv and json handling, csv file manipulation, data structure conversion, csv data to json services, convert csv for web applications, structured data tools, convert csv into json objects, JSON schema from CSV, csv file parsing, data migration tools, convert csv to json format online, csv rows to json format, convert csv files to json documents, csv parsing online, csv to json online app, online json converter, csv data formatting, csv file transformation, json data from csv files, csv to json processing tool, convert data to json format, data structure management, csv to json conversion software, csv text to json object, convert csv documents to json, csv record to json tool, csv conversion to json service, csv data formatting tools, json creation from csv files, free online csv to json converter, csv transformation app, CSV to JSON web converter, data extraction to json, csv to json for web development, online csv to json processor, csv data to json conversion tool, fast csv json conversion, online document conversion, csv file to json conversion, online csv record transformer."
  />
</Helmet>

  <div className="text-center">
        <h1 className="title_lg">CSV to JSON converter</h1>
        <p className="mt_5">Converter your CSV files to JSON</p>
      </div>
      <div className='container'>
            <div className="uploader-container uploader-container--images">
              <form className="file-upload-form">
                <label htmlFor="file" className="file-upload-label">
                  <div className="file-upload-design">
                    <svg viewBox="0 0 640 512" height="1em">
                      <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                    </svg>
                    <p>Drag and Drop</p>
                    <p>or</p>
                    <span className="browse-button">Browse file</span>
                  </div>
                  <input
                    id="file"
                    type="file" accept=".csv" onChange={handleCsvFileChange}
                  />
                </label>
              </form>
            </div>
        </div>
    <div className='container'>
        <textarea
        placeholder="Or paste JSON here"
        rows="10"
        cols="50"
        value={csvData} onChange={handleCsvChange}
      ></textarea>
      <br />
      <button className='normal-button' onClick={convertCsvToJson}>Convert to JSON</button>
      <br />
      {jsonData && <button className='Download-button my-2' onClick={downloadJson}>Download JSON</button>}
      {jsonData && (
        <div className='container'>
          <h3>Converted JSON Data:</h3>
          <textarea rows="10" cols="50" readOnly value={jsonData}></textarea>
        </div>
      )}
    </div>
    <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to convert your CSV files into JSON format, making it easier to work with data in web applications and APIs. Simply upload your CSV file and click "Convert" to download the JSON file.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Convert Your CSV Files to JSON</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Upload the CSV file you wish to convert by clicking the "Upload" button.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Review the data if necessary to ensure it's formatted correctly.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Convert" button to start the conversion process.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Once the conversion is complete, download your JSON file using the provided link.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
</>
  );
};

export default CsvToJsonConverter;
