import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom"; 
import Loader from "./Loader";
import { Helmet } from "react-helmet";

const educationLevels = ["A small paragraph", "a paragraph"];
const ContentShortner = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const [input, setInput] = useState("");
  const [educationLevel, setEducationLevel] = useState("A paragraph");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const outputRef = useRef(null);
  const [isValid, setIsValid] = useState(true);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const wordCount = inputValue.trim().split(/\s+/).length;
    if (wordCount < 5) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    const characterCount = inputValue.length;
    document.getElementById("character-counter").innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input ||  !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Shorten the content: "${input}" to "${educationLevel}" `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph ;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleEducationLevelChange = (event) => {
    setEducationLevel(event.target.value);
  };
    const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setEducationLevel("Formal"); // Reset education level to default value
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    Content Shortener - Shorten Your Content Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/ContentShortner"
  />
  <meta
    name="description"
    content="Shorten your content online. Perfect for condensing long texts while retaining key information."
  />
  <meta
    name="keywords"
    content="content shortener, shorten text, content summarizer, text reduction tool, text shortening, online content shortener, quick content reducer, concise writing tool, text summarizing tool, paragraph shortening, online text editor, efficient content shortening, brief content generator, text condensing tool, summarize text online, text trimming tool, online summarization tool, text brevity tool, compress content, text reduction service, content condensation, text summarizer online, easy content shortener, digital text reduction, efficient summarization, summary creator, online writing assistant, writing efficiency tool, clarity improvement tool, reduce word count, quick summary generator, streamlined writing, text editor online, content optimization tool, content shortening service, summarize articles online, cut down text length, shorten paragraphs, content summarizing tool, writing enhancement tool, brevity in writing, summarization service, online summarizer, reduce text volume, content synthesis tool, text trimming service, professional content shortener, concise paragraph generator, short form content creator, content synthesis service, precision writing tool, edit text for brevity, fast text reducer, writing aid, content length reducer, digital summarization tool, reduce content clutter, online writing tool, concise content creator, professional text shortener, quality text reduction, simplify content, text digest tool, writing efficiency booster, edit for conciseness, shorten your writing, content clarity tool, 
    articulate concise ideas, summary enhancer, content simplifier, writing support tool, swift content reducer, impactful summarization, fast content shortener, optimize text clarity, clarity-focused writing, improve text quality, content editor online, summary writing service, smart text reduction, rewrite for brevity, content summarizing service, effective writing tool, concise writing service, article shortening tool, brief writing assistant, digital text editing, cut text length tool, high-quality text summarization, summarizing tool online, text condensation service, reduce content complexity, swift summary generator, text optimization tool, effective summarizer, concise communication tool, effective content shortener, clear and concise writing, content rephrasing service, improve text understanding, summarize writing effectively, articulate ideas concisely, digital content summarizer, text clarity enhancer, enhance writing quality, professional content summarizing tool, text reducer online, concise text writing, practical content shortener"
  />
</Helmet>

    <div className="text-center">
        <h1 className="title_lg">Content Shortner</h1>
        <p className="mt_5">Make your Content Shortner</p>
      </div>
      <div
        className="summarizer container" 
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Make Content Short</h4>
              <p>What's your content you want to make Short?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/100
                  </span>
                </div>
                <textarea
                 ref={textAreaRef}
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="enter your content here"
                  rows="9"
                  cols="100"
                  maxLength="1000"
                  id="prompt"
                  className={`form-control ${!isValid ? "is-invalid" : ""}`}
                ></textarea>
                 {!isValid && (
                  <div className="invalid-feedback" style={{ display: "block", color: "red" }}>
                    <span>Please enter at least 5 words</span>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Length of text you want</label>
                <select
                  className="form-control my-2"
                  value={educationLevel}
                  onChange={handleEducationLevelChange}
                >
                  {educationLevels.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled" ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Generated Post</h4>
                  <p>Here is your Generated LinkedIn post</p>
                </div>
              </div>
              <div className="form-group">
                <div   className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                          <div className="loader-container">
                            <Loader />
                          </div>
                        ) : (
                          messages.length > 0 &&
                          currentIndex !== -1 && (
                            <div className="p-3">
                              {messages[currentIndex].content}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button
                    className="buttonDownload "
                    onClick={handleDownload}
                    disabled={messages.length === 0}
                  >
                    Download
                  </button>
                <button className="Btn" onClick={handleCopy}>
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="tool-detail mt-5">
  This tool helps you shorten lengthy content while retaining its key points and meaning. Whether you're condensing an article, essay, or any form of text, simply input your content, and the tool will generate a concise version that effectively conveys the main ideas.
</p>
<div className="row align-items-center small_gutter">
  <div className="col-lg-5 mt_30">
    <h3 className="sub_title">STEP BY STEP</h3>
    <h2 className="title">How To Shorten Your Content</h2>
    <p className="mt_25">Follow the steps below</p>
    <div className="step_item_box pt_10">
      <div className="step_item mt_30">
        <h4>Step 1</h4>
        <p className="mt_5">
          Enter the text you want to shorten in the provided input field.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 2</h4>
        <p className="mt_5">
          Click the "Shorten Content" button to generate a condensed version of your text.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 3</h4>
        <p className="mt_5">
          Review the shortened content and ensure it maintains the key points and clarity you need.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 4</h4>
        <p className="mt_5">
          Use the shortened version in your content or make adjustments as needed.
        </p>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default ContentShortner;
