import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const messageStyle = {
  padding: "10px",
  borderRadius: "10px",
  maxWidth: "100%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const educationLevels = ["High School", "College", "Postgraduate"];
const Chatgpt = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const outputRef = useRef(null);
  const [paragraphs, setParagraphs] = useState(2);
  const [educationLevel, setEducationLevel] = useState("High School");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  const outputContainerRef = useRef(null);
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (!isLoading && messages.length > 0 && outputContainerRef.current) {
      outputContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, isLoading]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const wordCount = inputValue.trim().split(/\s+/).length;
    if (wordCount < 5) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
    const characterCount = inputValue.length;
    document.getElementById("character-counter").innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    const wordCount = input.trim().split(/\s+/).length;
    if (wordCount < 5) {
      alert("Please enter at least 5 words for the topic.");
      return;
    }
    try {
      setIsLoading(true);
      const prompt = `Write an essay on: "${input}" of "${paragraphs}" for the education level "${educationLevel}"`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2); 
    setEducationLevel("High School"); 
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleParagraphsChange = (event) => {
    const newValue = parseInt(event.target.value);
    setParagraphs(isNaN(newValue) ? 0 : newValue);
  };
  const handleEducationLevelChange = (event) => {
    setEducationLevel(event.target.value);
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Essay Writer - Write Essays Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/EssayWriter" />
  <meta
    name="description"
    content="Write essays online. Perfect for academic writing, essay generation, and creative essays."
  />
  <meta
    name="keywords"
    content="essay writer, write essays, essay generator, essay writing tool, online essay creator, academic essay writer, generate essays, essay composition tool, creative essay writing, essay structure, thesis writing assistant, persuasive essays, narrative essay writer, write research essays, essay outline tool, fast essay generator, online writing software, personalized essay writing, academic content creation, essay assistance."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Essay Writer</h1>
        <p className="mt_3">Write essays on any topic</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Essay Topic</h4>
              <p>What's your essay about?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/60
                  </span>
                </div>
                <textarea
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="i.e., the importance of Laws in a state"
                  rows="5"
                  cols="100"
                  maxLength="60"
                  id="prompt"
                  className={`form-control ${!isValid ? "is-invalid" : ""}`}
                ></textarea>
                {!isValid && (
                  <div className="invalid-feedback" style={{ display: "block", color: "red" }}>
                    <span>Please enter at least 5 words</span>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="number_answers">Number of Paragraphs</label>
                <input
                  type="number"
                  className="form-control my-2"
                  value={paragraphs}
                  onChange={handleParagraphsChange}
                  placeholder="Number of paragraphs"
                />
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Education Level</label>
                <select
                  className="form-control my-2"
                  value={educationLevel}
                  onChange={handleEducationLevelChange}
                >
                  {educationLevels.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled" ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Your Essay</h4>
                  <p>Here is your generated essay!</p>
                </div>
              </div>
              <div className="form-group">
                <div className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          data-original-title="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "370px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                        ref={outputContainerRef}
                      >
                        {isLoading ? (
                          <div className="loader-container">
                            <Loader />
                          </div>
                        ) : (messages.length > 0 && currentIndex !== -1 && (
                          <div style={messageStyle}>
                            {messages[currentIndex].content}
                          </div>
                        ))}
                      </div>
                    </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button className="buttonDownload" onClick={handleDownload}
                    disabled={messages.length === 0}>Download</button>
                <button className="Btn">
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="tool-detail mt-5">
  This tool helps you write essays by generating well-structured content based on the topics or ideas you provide. Whether you need help with the introduction, body, or conclusion, simply enter your topic, and the tool will assist you in creating a comprehensive essay.
</p>
<div className="row align-items-center small_gutter">
  <div className="col-lg-5 mt_30">
    <h3 className="sub_title">STEP BY STEP</h3>
    <h2 className="title">How To Write an Essay</h2>
    <p className="mt_25">Follow the steps below</p>
    <div className="step_item_box pt_10">
      <div className="step_item mt_30">
        <h4>Step 1</h4>
        <p className="mt_5">
          Enter your essay topic or a brief outline of the ideas you want to cover in the input field.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 2</h4>
        <p className="mt_5">
          Enter your number of paragraphs and select the level of education ideas you want to cover in the input field.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 3</h4>
        <p className="mt_5">
          Click the "Generate Essay" button to produce an essay draft based on the information provided.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 4</h4>
        <p className="mt_5">
          Use the essay as is or refine it to fit your writing style and requirements. You can also repeat the process for individual sections like the introduction or conclusion. 
        </p>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default Chatgpt;
