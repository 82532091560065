import React, { useState,useEffect } from 'react';
import Loader from './Loader1';
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';
const CsvSplitter = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(1);
  const [maxRows, setMaxRows] = useState(1);
  const [splitCsvData, setSplitCsvData] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
 const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFileChange = (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvData = e.target.result;
      const rows = csvData.split('\n');
      setMaxRows(rows.length);
      setTotalRows(rows.length);
    };
    reader.readAsText(selectedFile);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleStartRowChange = (event) => {
    setStartRow(parseInt(event.target.value, 10));
  };

  const handleEndRowChange = (event) => {
    setEndRow(parseInt(event.target.value, 10));
  };

  const splitCsv = () => {
    setLoading(true);

    try {
      if (startRow < 1 || endRow < startRow || endRow > maxRows) {
        alert('Invalid row range');
        setLoading(false);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const csvData = e.target.result;
        const rows = csvData.split('\n');
        
        const slicedRows = rows.slice(startRow - 1, endRow);
        const newCsvData = slicedRows.join('\n');
        
        setSplitCsvData(newCsvData);
        setLoading(false);
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error splitting CSV file:', error);
      setLoading(false);
    }
  };

  const downloadSplitCsv = () => {
    if (splitCsvData) {
      const blob = new Blob([splitCsvData], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = 'split_csv.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>CSV Splitter - Split CSV Files Online</title>
  <link rel="canonical" href="http://toolsbag.io/PdfTools/CsvSplitter" />
  <meta
    name="description"
    content="Easily split your CSV files into multiple smaller files online. Perfect for managing large datasets and separating CSV files into manageable parts."
  />
  <meta
    name="keywords"
    content="csv splitter, split csv, divide csv file, split csv online, separate csv files, csv file management, csv data processing, online csv tools, split large csv, csv file divider, csv file handling, csv data segmentation, online csv splitter, csv to separate files, csv chunking, manage csv datasets, csv processing tool, efficient csv splitter, quick csv split, csv file organizer, online data separation, csv file division, csv to parts, easy csv splitting, csv data management, csv file utility, online file splitter, csv line splitter, split csv by rows, csv data tools, csv editing online, divide csv into smaller files, csv data handling, csv segmenter, csv splitting service, fast csv file division, csv data manipulation, split csv by size, separate csv into sections, csv to individual files, csv file formatting, cloud csv splitter, csv organization tool, online data management, csv parser, csv file chunker, csv data organization, csv partitioning, automated csv splitting, csv file breakdown, csv row separation, csv data division, simple csv tools, csv data handling solutions, csv editing tool, csv to smaller files, split csv columns, csv separation service, csv format tool, split csv for analysis, manage csv files easily,
     csv data extraction, csv table splitter, online file management, csv splitting application, csv processing application, csv file processor, csv dataset management, divide large csv files, separate csv data for analysis, split csv by criteria, csv management software, csv line management, csv data cleanup, online csv division tool, split csv into manageable sections, csv data extraction tool, quick csv processing, online csv utility, csv file organization service, csv files into parts, easy csv organizer, csv division application, online data manipulation, csv editing service, separate csv data quickly, csv file chunk management, cloud-based csv splitter, csv data analysis tools, csv file division utility, split csv with ease, csv data entry management, csv processing solutions, csv to rows, csv segment tool, csv splitting and management, csv data structure management, csv line division, split csv files easily, csv data organization services, csv organization software, efficient csv management tool, csv file organization online, split csv datasets, csv data file handling, csv data formatting service, online csv file splitter, csv analysis and management, split csv file online, csv to parts management, csv division for analysis, csv file separation service, csv data organization solutions, csv row management tool, csv management online, csv processing services, csv data handler, csv formatting tool, csv analysis services, online csv segmenter."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">CSV Splitter</h1>
        <p className="mt_5">Split your CSV files</p>
      </div>
      <div className="container">
            <div className="uploader-container uploader-container--images">
              {loading ? (
                <Loader />
              ) : !file ? (
                <form className="file-upload-form">
                  <label htmlFor="file" className="file-upload-label">
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                      </svg>
                      <p>Drag and Drop</p>
                      <p>or</p>
                      <span className="browse-button">Browse file</span>
                    </div>
                    <input
                      id="file"
                      accept=".csv"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </label>
                </form>
              ) : (
                <>
                  <div
                    style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                    className="my-1"
                  >
                    {file && `${file.name} (${totalRows} rows)`}
                  </div>
                  <div className="input-container">
                    <input
                      id="input-start"
                      required=""
                      type="number"
                      value={startRow}
                      onChange={handleStartRowChange}
                      className="form-control text-input"
                      min="1"
                      max={maxRows}
                    />
                    <label htmlFor="input-start" className="label">
                      Start Row Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <div className="input-container">
                    <input
                      id="input-end"
                      required=""
                      type="number"
                      value={endRow}
                      onChange={handleEndRowChange}
                      className="form-control text-input"
                      min="1"
                      max={maxRows}
                    />
                    <label htmlFor="input-end" className="label">
                      End Row Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <div className="container">
                    <button className="normal-button" onClick={splitCsv}>
                      Split CSV
                    </button>
                    {splitCsvData && (
                      <button className="buttonDownload mx-1" onClick={downloadSplitCsv}>
                        Download Split CSV
                      </button>
                    )}
                    <button className="Reset-button" onClick={() => setFile(null)}>
                      Reset
                    </button>
                  </div>
                </>
              )}
            </div>
      </div>
      <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to split large CSV files into smaller, more manageable files. Simply upload your CSV file, choose the number of rows per split, and click "Split" to save the smaller files.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Split Your CSV Files</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Upload the CSV file you want to split by clicking the "Upload" button.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Specify the number of rows you want in each split file.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Split" button to begin the splitting process.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            After the process is complete, download the smaller CSV files or access them through the provided links.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default CsvSplitter;
