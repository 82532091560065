import React, { useState, useRef, useEffect } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
const messageStyle = {
  padding: "20px",
  borderRadius: "10px",
  maxWidth: "100%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const BusinessSlogan = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const textAreaRef = useRef(null);
  const textAreaRef1 = useRef(null);
  const textAreaRef2 = useRef(null);
  const [input1, setInput1] = useState("");
  const [input, setInput] = useState("");
  const outputRef = useRef(null);
  const [paragraphs, setParagraphs] = useState(6);
  const [educationLevel, setEducationLevel] = useState("Formal");
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/100`;
  };

  const clickHandle = async () => {
    if (!input || !paragraphs || !educationLevel) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Generate the business slogan for business and name of business is: "${input}" and this business "${input1}" do `;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });

      const userMessage = { role: "user", content: prompt };

      const averageTokensPerSentence = 20; // Adjust as needed
      const sentencesPerParagraph = 10; // Adjust as needed
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };

      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleChange1 = (event) => {
    const inputValue = event.target.value;
    setInput1(inputValue);
    const characterCount = inputValue.length;
    document.getElementById("character-counter1").innerText = `${characterCount}/100`;
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs(2);
    setEducationLevel("Formal");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };
  const handleCopy = () => {
    if (textAreaRef.current) {
      const textToCopy = messages[currentIndex]?.content || "";
      navigator.clipboard.writeText(textToCopy)
        .then(() => alert("Copied to clipboard!"))
        .catch((error) => console.error("Error copying to clipboard:", error));
    }
  };
  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Business Slogan Writer - Generate Catchy Slogans Online</title>
  <link rel="canonical" href="http://toolsbag.io/AiTools/BusinessSlogan" />
  <meta
    name="description"
    content="Easily generate catchy and creative business slogans online. Ideal for branding, marketing campaigns, and business promotions."
  />
  <meta
    name="keywords"
    content="business slogan writer, slogan generator, create business slogan, write slogan online, catchy business slogans, generate slogans, online slogan creator, business tagline maker, marketing slogan generator, catchy taglines, unique slogans, branding slogans, brand name ideas, catchy phrases, creative slogans, advertising slogans, promotional slogans, slogan ideas, business branding, memorable slogans, innovative slogans, online branding tool, marketing tool, slogan writing, business marketing slogans, catchy marketing phrases, effective slogans, business catchphrases, slogan maker, tagline generator, create catchy taglines, impactful slogans, slogan ideas for businesses, catchy business phrases, business identity slogans, short slogans, attention-grabbing slogans, brand messaging, custom slogans, slogan development, digital slogan creator, slogan design, slogan inspiration, quick slogan generator, brainstorming slogans, catchy business taglines, smart slogans, online slogan writing, compelling slogans, business motto generator, effective brand slogans, slogan crafting, creative tagline ideas, promotional tagline generator, user-friendly slogan tool, digital marketing slogans, catchy words for slogans, slogan concepts, innovative tagline generator, powerful slogans, brand recognition slogans, advertising taglines, brand loyalty slogans, appealing slogans, service slogans, business promotion ideas, catchy company slogans, slogan suggestions, simple slogan generator, slogan brainstorming tool, catchy phrases for branding, fresh slogans, engaging slogans, advertising campaigns, promotional ideas, marketing catchphrases, catchy phrases for businesses, attention-grabbing taglines, brand communication slogans."
  />
</Helmet>


    <div className="text-center">
        <h1 className="title_lg">Business Slogan writer</h1>
        <p className="mt_5">This will suggest you business slogans for your business</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Your Business</h4>
              <p>For which business you want slogan?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">0/100</span>
                </div>
                <p>Name of your business:</p>
                <textarea
                  ref={textAreaRef1}
                  onKeyDown={handleKeyPress}
                  onChange={handleChange}
                  placeholder="e.g. Best sellers"
                  rows="5"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter1" className="counter">0/100</span>
                </div>
                <p>What's your business do?</p>
                <textarea
                  ref={textAreaRef2}
                  onChange={handleChange1}
                  placeholder="e.g. Sell books"
                  rows="4"
                  cols="100"
                  maxLength="100"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled" ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Generated Slogans</h4>
                  <p>Here is your generated Slogans!</p>
                </div>
              </div>
              <div className="form-group">
                <div   className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                    <div className="loader-container">
                      <Loader />
                    </div>
                  ) : (messages.length > 0 && currentIndex !== -1 && (
                          <div style={messageStyle}>
                            {messages[currentIndex].content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button className="buttonDownload my-" onClick={handleDownload}
                    disabled={messages.length === 0}>Download</button>
                <button className="Btn" onClick={handleCopy}>
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
  <p className="tool-detail">
    This tool helps you generate catchy and memorable slogans for your business. Simply enter a few details about your brand, and click "Generate" to get creative slogan ideas.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Generate a Business Slogan</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Enter the name of your business and a brief description of what you do.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            (Optional) Specify any keywords or themes you want to include in your slogan.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Generate" button to receive a list of catchy slogans tailored for your business.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Review the generated slogans and choose the one that resonates best with your brand.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default BusinessSlogan;
