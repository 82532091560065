import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SideBarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
  background: black;
  height: 60px;
  display: flex;
  justify-content: space-between; // Adjusted to space between for placing items at both ends
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure it stays above other content */
  padding: 0 1rem; // Added padding for spacing
`;

const NavIcon = styled(Link)`
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #f7f7f7;
  width: 25vw;
  max-width: 335px;
  height: 100vh;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  left: ${({ $sidebar }) =>
    $sidebar ? "0" : "-100%"}; // Use $sidebar instead of sidebar
  transition: 350ms;
  z-index: 10;
  overflow-y: auto;
  border-right: 1px solid black;

  @media (max-width: 768px) {
    width: 50vw;
  }

  @media (max-width: 480px) {
    width: 75vw;
  }
`;

const SidebarWrap = styled.div`
  width: 100%;
`;
const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);
  const sidebarRef = useRef(null);
  const showSidebar = () => {
    setSidebar((prevState) => !prevState);
  };
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setSidebar(false);
    }
  };
  useEffect(() => {
    if (sidebar) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebar]);

  return (
    <>
      <IconContext.Provider value={{ color: "white" }}>
        <Nav style={{ backgroundColor: "#008080" }}>
          <NavIcon to="#">
            {sidebar ? (
              <AiIcons.AiOutlineClose
                style={{ color: "black" }}
                onClick={showSidebar}
              />
            ) : (
              <FaIcons.FaBars
                style={{ color: "black" }}
                onClick={showSidebar}
              />
            )}
          </NavIcon>
          <Link to="/">
            <h1 className="heading">&nbsp;&nbsp;&nbsp;&nbsp;Tools Bag</h1>
          </Link>

          <p style={{color: "white"}}>Free Tools</p>
        </Nav>
        <SidebarNav ref={sidebarRef} $sidebar={sidebar}>
          <SidebarWrap>
            {SidebarData.map((item, index) => (
              <SubMenu style={{ height: "20px" }} item={item} key={index} />
            ))}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
