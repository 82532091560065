import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Loader from "./Loader1";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const BackGround = () => {
  const inputRef = useRef(null);
  const imageContainerRef = useRef(null);
  const [processedImage, setProcessedImage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    if (processedImage && imageContainerRef.current) {
      imageContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [processedImage]);
  const handleFileChange = (event) => {
    setLoading(true);
    setFile(event.target.files[0]);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const removeBackground = async () => {
    if (!file) {
      console.error("Please select a file.");
      return;
    }
    const formData = new FormData();
    formData.append("size", "auto");
    formData.append("image_file", file);
    try {
      setLoading(true);
      const response = await axios.post(
        "https://api.remove.bg/v1.0/removebg",
        formData,
        {
          headers: {
            "X-Api-Key": "jKdrdYgxEPh173C1gGeM8g8V",
            "Content-Type": "multipart/form-data",
          },
          responseType: "arraybuffer",
        }
      );
      if (response.status === 200) {
        const imageUrl = URL.createObjectURL(new Blob([response.data]));
        setProcessedImage(imageUrl);
      } else {
        console.error("Error:", response.status, response.statusText);
      }
    } catch (error) {
      console.error("Request failed:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    if (processedImage) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = processedImage;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        context.globalCompositeOperation = "destination-over";
        context.fillStyle = selectedColor;
        context.fillRect(0, 0, canvas.width, canvas.height);
        const dataUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "processed_image_with_background.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    }
  };
  const handleReset = () => {
    setProcessedImage(null);
    setFile(null);
    setSelectedColor("#ffffff");
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  const handleColorChange = (event) => {
    setSelectedColor(event.target.value);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Image Background Remover - Remove Backgrounds from Images Online
        </title>
        <link rel="canonical" href="http://toolsbag.io/ImageTools/BackGround" />
        <meta
          name="description"
          content="Easily remove backgrounds from your images online in just a few clicks. Perfect for designers, e-commerce, and social media users."
        />
        <meta
          name="keywords"
          content="background remover, remove image background, online background removal, transparent background, photo editing, image editing"
        />
      </Helmet>

      <div className="container">
        <div className="text-center">
          <h1 className="title_lg">Image Background remover</h1>
          <p className="mt_5">Remove background of your images</p>
        </div>
        <div className="container">
          <div className="row justify-content-center mb-4">
            <div className="col-xl-10">
              <div className="uploader-container">
                {loading ? (
                  <Loader />
                ) : !file ? (
                  <form className="file-upload-form">
                    <label htmlFor="file" className="file-upload-label">
                      <div className="file-upload-design">
                        <svg viewBox="0 0 640 512" height="1em">
                          <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                        </svg>
                        <p>Drag and Drop</p>
                        <p>or</p>
                        <span className="browse-button">Browse file</span>
                      </div>
                      <input
                        className="form-control"
                        type="file"
                        id="file"
                        ref={inputRef}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </label>
                  </form>
                ) : (
                  <>
                    <div
                      style={{
                        textDecoration: "Underline",
                        fontWeight: "bold",
                      }}
                    >
                      {file && file.name}
                    </div>
                    <button
                      disabled={!file}
                      style={{ marginTop: "40px" }}
                      className="normal-button my-"
                      onClick={removeBackground}
                    >
                      Remove Background
                    </button>
                    <button onClick={handleReset} className="Reset-button my-3">
                      Reset
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div>
            {processedImage && (
              <div className="output-container">
                <div
                  className="image-container"
                  ref={imageContainerRef}
                  style={{ backgroundColor: selectedColor }}
                >
                  <img src={processedImage} alt="Processed" />
                </div>
                <div className="controls-container">
                  <button
                    style={{ margin: "10px 0" }}
                    className="buttonDownload"
                    onClick={handleDownload}
                  >
                    Download Image
                  </button>
                  <button
                    style={{ margin: "10px 0" }}
                    className="Reset-button"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                  <div className="mt-2 d-flex align-items-center mb-10">
                    <span className="me-2">Background Color:</span>
                    <input
                      type="color"
                      value={selectedColor}
                      onChange={handleColorChange}
                      className="form-control color-input"
                      style={{width:"30px", height: "30px", padding: "0"}}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container my-5">
        <p className="tool-detail">
          This tool allows you to remove the background from your images quickly
          and easily. Simply upload your image below, and the background will be
          removed automatically, leaving you with a clean, transparent image.
        </p>
        <div className="row align-items-center small_gutter">
          <div className="col-lg-5 mt_30">
            <h3 className="sub_title">STEP BY STEP</h3>
            <h2 className="title">How To Remove Image Background</h2>
            <p className="mt_25">Follow along with the steps below</p>
            <div className="step_item_box pt_10">
              <div className="step_item mt_30">
                <h4>Step 1</h4>
                <p className="mt_5">
                  Upload the image you want to remove the background from.
                </p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 2</h4>
                <p className="mt_5">
                  The tool will automatically process the image and remove its
                  background.
                </p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 3</h4>
                <p className="mt_5">
                  Once the background is removed, click "Download Image" to save
                  the new transparent image.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BackGround;
