import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "./SearchContext";
import search from '../images/search.svg'
import { useLocation } from "react-router-dom";
import ppt from "./pics/powerpoint.svg";
import pdfi from "./pics/pdf-file-icon.svg";
import word from "./pics/word.svg";
import imag from "./pics/ima.svg";
import num from "./pics/numbers.svg";
import split from "./pics/spli.svg";
import excel from "./pics/excel.svg";
import csv from "./pics/csv.svg";
import xml from "./pics/xml.svg";
import merge from "./pics/merge.svg";
import { Helmet } from "react-helmet";
const ToolCard = ({ id, title, description, path, icon }) => (
  <div className="pro">
    <Link to={path} id={id}>
      <div className="des">
        <p className="tooln">
          <strong>{title} </strong>
        </p>
        <h5>PDF and Files Tools</h5>
        <h4>{description}</h4>
      </div>
      <img
        src={icon}
        alt="Text SVG"
        style={{
          width: "24px",
          height: "24px",
          fill: "#06685f",
          transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
        }}
      />
    </Link>
  </div>
);
export const cards = [
  {
    id: "pdfConverter",
    title: "Image to PDF",
    description: "Convert your images to PDF file",
    path: "/PdfTools/Image-to-PDF",
    icon: pdfi,
  },
  {
    id: "jpgToPdf",
    title: "JPG to PDF",
    description: "Convert your JPG images to PDF file",
    path: "/PdfTools/Jpg-to-PDF",
    icon: pdfi,
  },
  {
    id: "webpToPdf",
    title: "WEBP to PDF",
    description: "Convert your WEBP images to PDF file",
    path: "/PdfTools/webp-to-PDF",
    icon: pdfi,
  },
  {
    id: "pngToPdf",
    title: "PNG to PDF",
    description: "Convert your PNG images to PDF file",
    path: "/PdfTools/png-to-PDF",
    icon: pdfi,
  },
  {
    id: "jpegToPdf",
    title: "JPEG to PDF",
    description: "Convert your JPEG images to PDF file",
    path: "/PdfTools/Jpeg-to-PDF",
    icon: pdfi,
  },
  {
    id: "wordtopdf",
    title: "Word to PDF",
    description: "Convert your word document to PDF",
    path: "/PdfTools/WordToPdfConverter",
    icon: word,
  },
  {
    id: "pdftoword",
    title: "PDF Text Extractor ",
    description: "Extractor text from PDF",
    path: "/PdfTools/pdfTextExtractor",
    icon: word,
  },
  {
    id: "ppttopdf",
    title: "Pdf to PowerPoint Converter",
    description: "Convert PDF to PowerPoint slides",
    path: "/PdfTools/pdftoppt",
    icon: ppt,
  },
  {
    id: "pdfConverter",
    title: "Image to PDF",
    description: "Convert images to PDF format",
    path: "/PdfTools/Image-to-PDF",
    icon: imag,
  },
  {
    id: "pdftoimages",
    title: "PDF to images",
    description: "Convert your PDF to images",
    path: "/PdfTools/pdftoimage",
    icon: imag,
  },
  {
    id: "PageNumberWriter",
    title: "Page Number Writes",
    description: "Write page numbers on pages of pdf",
    path: "/PdfTools/PageNumberWriter",
    icon: num,
  },
  // {
  //   id: "PdfMergeComponent",
  //   title: "Pdf Merger",
  //   description: "Merge PDF's",
  //   path: "/PdfTools/PdfMergeComponent",
  //   icon: FaFileImage,
  // },
  {
    id: "PdfSplitter",
    title: "PDF Splitter",
    description: "Split your PDF's",
    path: "/PdfTools/PdfSplitter",
    icon: split,
  },
  {
    id: "pdfToJpeg",
    title: "PDF to JPEG Converter",
    description: "Convert PDF files to JPEG",
    path: "/PdfTools/pdftojpeg",
    icon: imag,
},
{
    id: "pdfToPng",
    title: "PDF to PNG Converter",
    description: "Convert PDF files to PNG",
    path: "/PdfTools/pdftopng",
    icon: imag,
},
{
    id: "pdfToJpg",
    title: "PDF to JPG Converter",
    description: "Convert PDF files to JPG",
    path: "/PdfTools/pdftojpg",
    icon: imag,
},
{
  id: "pdfToSvg",
  title: "PDF to SVG Converter",
  description: "Convert PDF files to SVG",
  path: "/PdfTools/pdftosvg",
  icon: imag,
},
  {
    id: "ExcelToPdfConverter",
    title: "Excel To PDF Converter",
    description: "Convert Excel files to PDF's",
    path: "/PdfTools/ExcelToPdfConverter",
    icon: excel,
  },
  {
    id: "CsvToExcelConverter",
    title: "CSV To Excel Converter",
    description: "Convert your CSV files to Excel",
    path: "/PdfTools/CsvToExcelConverter",
    icon: csv,
  },
  {
    id: "ExcelSplitter",
    title: "Excel Splitter",
    description: "Split your Excel files ",
    path: "/PdfTools/ExcelSplitter",
    icon: split,
  },
  {
    id: "JsonToXmlConverter",
    title: "Json To Xml Converter",
    description: "Convert JSON files to XML ",
    path: "/PdfTools/JsonToXmlConverter",
    icon: xml,
  },
  {
    id: "CsvSplitter",
    title: "CSV Splitter",
    description: "Split your CSV files ",
    path: "/PdfTools/CsvSplitter",
    icon: split,
  },
  {
    id: "CsvToJsonConverter",
    title: "CSV To Json Converter",
    description: "Convert CSV files to JSON",
    path: "/PdfTools/CsvToJsonConverter",
    icon: csv,
  },
  {
    id: "ExcelToCsvConverter",
    title: "Excel To CSV Converter",
    description: "Convert Excel to CSV",
    path: "/PdfTools/ExcelToCsvConverter",
    icon: excel,
  },
  {
    id: "PdfMerger",
    title: "Pdf Merger",
    description: "Merge PDF's",
    path: "/PdfTools/PdfMerger",
    icon: merge,
  },
  {
    id: "htmlToPdf",
    title: "HTML to PDF converter",
    description: "Convert your HTML, CSS in PDF document" ,
    path: "/PdfTools/htmlToPdf",
    icon: xml,
  },
];
const PdfTools = () => {
  const { searchTerm, setSearchTerm } = useSearch();
  useEffect(() => {
  return () => {
    setSearchTerm("");
  };
}, [setSearchTerm]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const displayCards = searchTerm
    ? cards.filter((card) =>
        card.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : cards;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          PDF Tools - Free Online PDF Converter, Compressor & Editor
        </title>
        <link rel="canonical" href="http://toolsbag.io/PdfTools" />
        <meta
          name="description"
          content="Use our PDF tools to convert, compress, merge, and edit PDF files online for free. Fast, easy, and efficient PDF management for everyone."
        />
        <meta
          name="keywords"
          content="pdf tools, online pdf converter, compress pdf, merge pdf, edit pdf, pdf management, pdf tools online, free pdf converter,pdf tools, online pdf converter, compress pdf, merge pdf, edit pdf, pdf management, free pdf converter, pdf editor, convert pdf to word, convert pdf to jpg, convert jpg to pdf, pdf to png, pdf to excel, pdf to html, pdf to text, pdf to ppt, pdf to epub, pdf compressor, pdf merger, pdf splitter, pdf combiner, pdf organizer, online pdf tools, free online pdf tools, fast pdf converter, easy pdf converter, pdf manipulation, pdf processing, pdf file management, pdf document management, pdf tools for students, pdf tools for professionals, pdf tools for businesses, pdf tools for educators, pdf tools for authors, pdf tools for graphic designers, pdf tools for photographers, pdf tools for marketers, pdf tools for freelancers, pdf tools for online courses, pdf tools for webinars, pdf tools for presentations, pdf tools for reports, pdf tools for contracts, pdf tools for invoices, pdf tools for legal documents, pdf tools for forms, pdf tools for brochures, pdf tools for resumes, pdf tools for portfolios, pdf tools for research papers, pdf tools for theses, pdf tools for dissertations, pdf tools for articles, pdf tools for newsletters, pdf tools for flyers, pdf tools for manuals, pdf tools for specifications, pdf tools for proposals, pdf tools for marketing materials, pdf tools for event planning, pdf tools for travel documents, pdf tools for healthcare documents, pdf tools for financial statements, pdf tools for accounting, pdf tools for tax documents, pdf tools for project management, pdf tools for quality assurance, pdf tools for compliance, pdf tools for audits, pdf tools for certifications, pdf tools for training materials, pdf tools for educational resources, pdf tools for teaching, pdf tools for learning, pdf tools for student assignments,
           pdf tools for academic research, pdf tools for scientific papers, pdf tools for technical documents, pdf tools for product manuals, pdf tools for user guides, pdf tools for specifications sheets, pdf tools for training guides, pdf tools for SOPs, pdf tools for process documentation, pdf tools for employee handbooks, pdf tools for company policies, pdf tools for internal communications, pdf tools for customer communications, pdf tools for client communications, pdf tools for presentations, pdf tools for visual aids, pdf tools for infographics, pdf tools for diagrams, pdf tools for illustrations, pdf tools for images, pdf tools for graphics, pdf tools for charts, pdf tools for graphs, pdf tools for data visualizations, pdf tools for statistical reports, pdf tools for performance reports, pdf tools for market analysis, pdf tools for business plans, pdf tools for strategic plans, pdf tools for feasibility studies, pdf tools for SWOT analysis, pdf tools for budgeting, pdf tools for forecasting, pdf tools for business intelligence, pdf tools for data analysis, pdf tools for research analysis, pdf tools for competitive analysis, pdf tools for market research, pdf tools for demographic analysis, pdf tools for audience segmentation, pdf tools for content marketing, pdf tools for social media marketing, pdf tools for SEO, pdf tools for PPC, pdf tools for email marketing, pdf tools for online advertising, pdf tools for branding, pdf tools for reputation management, pdf tools for influencer marketing, pdf tools for affiliate marketing, pdf tools for events, pdf tools for webinars, pdf tools for workshops, pdf tools for conferences, pdf tools for symposiums, pdf tools for trade shows, pdf tools for networking events, pdf tools for community engagement, pdf tools for public relations, pdf tools for press releases, pdf tools for media kits, pdf tools for presentations,
            pdf tools for speeches, pdf tools for talks, pdf tools for lectures, pdf tools for public speaking, pdf tools for panel discussions, pdf tools for interviews, pdf tools for testimonials, pdf tools for case studies, pdf tools for success stories, pdf tools for best practices, pdf tools for lessons learned, pdf tools for feedback, pdf tools for surveys, pdf tools for assessments, pdf tools for evaluations, pdf tools for reports, pdf tools for summaries, pdf tools for white papers, pdf tools for position papers, pdf tools for policy papers, pdf tools for discussion papers, pdf tools for opinion papers, pdf tools for research notes, pdf tools for study guides, pdf tools for reading materials, pdf tools for handouts, pdf tools for teaching aids, pdf tools for course materials, pdf tools for curriculum development, pdf tools for lesson plans, pdf tools for classroom resources, pdf tools for school projects, pdf tools for university projects, pdf tools for group assignments, pdf tools for thesis submissions, pdf tools for dissertation submissions, pdf tools for academic presentations, pdf tools for professional development, pdf tools for skill development, pdf tools for personal growth, pdf tools for lifelong learning, pdf tools for career advancement, pdf tools for job searching, pdf tools for resume building, pdf tools for interview preparation, and pdf tools for networking.
"
        />
      </Helmet>
      <div className="main1 ">
        <div className="pdf-body">
          <div className="root">
            <div className="top">
              <div className="title_lg">PDF and Files Tools</div>
            </div>
                <div class="home_content">
                  <p class="descr gray">Easy-to-use online PDF tools</p>
                  <form
                    action="#"
                    class="search_group search_group--search"
                    id="searchToolsForm"
                  >
                    <input type="text" name="q" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search" />
                    <img
                      class="search"
                      src={search}
                    />
                  </form>
          </div>
            <div className="pro-container">
              {displayCards.map((card, index) => (
                <ToolCard
                  key={index}
                  id={card.id}
                  title={card.title}
                  description={card.description}
                  path={card.path}
                  icon={card.icon}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PdfTools;
