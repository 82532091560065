import React, { useState, useCallback, useEffect } from 'react';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';

const RoundCropper = () => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onCropCompleteInternal = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const getCroppedImg = (imageSrc, croppedAreaPixels, shape = 'circle') => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const size = Math.min(croppedAreaPixels.width, croppedAreaPixels.height);
        canvas.width = size;
        canvas.height = size;

        if (shape === 'circle') {
          ctx.beginPath();
          ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
          ctx.clip();
        }

        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          croppedAreaPixels.width,
          croppedAreaPixels.height,
          0,
          0,
          size,
          size
        );

        canvas.toBlob((blob) => {
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }
          const croppedImageUrl = URL.createObjectURL(blob);
          resolve(croppedImageUrl);
        }, 'image/png');
      };
      image.onerror = reject;
    });
  };

  const handleCropImage = async () => {
    if (!croppedAreaPixels || !imageSrc) {
      console.error('Cropped area pixels or image source is invalid.');
      return;
    }
    try {
      const croppedImg = await getCroppedImg(imageSrc, croppedAreaPixels, 'circle');
      setCroppedImg(croppedImg);
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  };

  const handleDownloadCroppedImage = () => {
    if (!croppedImg) return;
    const link = document.createElement('a');
    link.href = croppedImg;
    link.download = 'cropped-circle-image.png';
    link.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result); 
      };
      reader.readAsDataURL(file);
    }
  };

  const handleReset = () => {
    setImageSrc(null);
    setCroppedImg(null);
    setCroppedAreaPixels(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Round Image Cropper - Create Profile Pictures Easily</title>
        <link rel="canonical" href="http://toolsbag.io/ImageTools/roundImageCropper" />
        <meta
          name="description"
          content="Easily crop images into round shapes for profile pictures. Upload your images and create perfect profile pics with our intuitive online tool."
        />
        <meta
          name="keywords"
          content="round image cropper, profile picture maker, crop images online, image cropping tool, circular cropper, avatar maker"
        />
      </Helmet>

      <div className="container">
        <div className="text-center">
          <h1 className="title_lg">Profile Picture Maker</h1>
          <p className="mt_5">Crop your images round and make your profile pictures</p>
        </div>
        
        {!imageSrc && (
          <div className="uploader-container uploader-container--images">
            <form className="file-upload-form">
              <label htmlFor="file" className="file-upload-label">
                <div className="file-upload-design">
                  <svg viewBox="0 0 640 512" height="1em">
                    <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                  </svg>
                  <p>Drag and Drop</p>
                  <p>or</p>
                  <span className="browse-button">Browse file</span>
                </div>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  className="mt-2 btn btn-primary w-75"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </label>
            </form>
          </div>
        )}

        {imageSrc && (
          <div className="cropper-container my-5 container">
            <div className="cropper-wrapper">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                showGrid={false}
                onCropChange={onCropChange}
                onCropComplete={onCropCompleteInternal}
                onZoomChange={onZoomChange}
              />
            </div>
            <div className="controls">
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, value) => onZoomChange(value)}
              />
              <button className="normal-button mx-2 my-1" onClick={handleCropImage}>
                Crop
              </button>
              <button 
                className="btn btn-success mx-2" 
                onClick={handleDownloadCroppedImage} 
                disabled={!croppedImg}
              >
                Download
              </button>
              <button className="Reset-button mx-2 my-1" onClick={handleReset}>
                Reset
              </button>
            </div>
          </div>
        )}
      </div>

      <div className="container my-5">
        <p className="tool-detail">
          This tool allows you to crop your images into a circular shape, perfect for profile pictures. Simply upload your image, adjust the crop area, and click "Crop" to process your image. After that, click "Download" to save the cropped image.
        </p>
        <div className="row align-items-center small_gutter">
          <div className="col-lg-5 mt_30">
            <h3 className="sub_title">STEP BY STEP</h3>
            <h2 className="title">How To Crop Your Images Round</h2>
            <p className="mt_25">Follow the steps below</p>
            <div className="step_item_box pt_10">
              <div className="step_item mt_30">
                <h4>Step 1:</h4>
                <p>Click on browse file and upload your image.</p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 2:</h4>
                <p>Use the slider to adjust the zoom of your image, then press the "Crop" button.</p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 3:</h4>
                <p>After cropping the image, click "Download" to save the cropped image to your device.</p>
              </div>
              <div className="step_item mt_30">
                <h4>Step 4:</h4>
                <p>If you want to start over, click "Reset."</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoundCropper;
