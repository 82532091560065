import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "./SearchContext";
import sign from './pics/article.svg'
import textIcon from "./pics/bold-text-icon.svg";
import pptIcon from "./pics/powerpoint.svg";
import search from '../images/search.svg'
import pdfIcon from "./pics/pdf-file-icon.svg";
import crop2Icon from "./pics/object-select-icon.svg";
import upscaleIcon from "./pics/expand-arrows-two-icon.svg";
import bgRemoverIcon from "./pics/color-adjust-icon.svg";
import compressorIcon from "./pics/compress.svg";
import image from "./pics/ima.svg";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
const ToolCard = ({ id, title, description, path, icon }) => (
  <div className="pro">
    <Link to={path} id={id}>
      <div className="des">
        <p className="tooln">
          <strong>{title}</strong>
        </p>
        <h5>Image Tool</h5>
        <h4>{description}</h4>
      </div>
        {icon && (
          <img
            style={{
              width: "24px",
              height: "24px",
              transition: "all 0.6s cubic-bezier(0.23, 1, 0.320, 1)",
              fill: "red",
            }}
            className="svg-icon"
            src={icon}
            alt="Icon"
          />
        )}{" "}
    </Link>
  </div>
);

export const cards = [
  {
    id: "esignature",
    title: "E Signature Maker",
    description: "Make virtual signatures for soft copy forms",
    path: "/ImageTools/esignature",
    icon: sign,
  },
  {
    id: "compressor",
    title: "Image Compressor",
    description: "Compress image size",
    path: "/ImageTools/imageCompressor",
    icon: compressorIcon,
  },
  {
    id: "resizer",
    title: "Image Resizer",
    description: "Resize image dimentions",
    path: "/ImageTools/imageResizer",
    icon: compressorIcon,
  },
  {
    id: "cropper",
    title: "Image Cropper",
    description: "Crop your image",
    path: "/ImageTools/ImageCropper",
    icon: crop2Icon,
  },
  {
    id: "addText",
    title: "Add text to Image",
    description: "Add any text at any location of image",
    path: "/ImageTools/Add-text-to-Image",
    icon: textIcon,
  },
  {
    id: "pdftoimage1",
    title: "Pdf to Image",
    description: "Convert PDF files to images",
    path: "/ImageTools/pdftoimage",
    icon: pdfIcon,
  },
  {
    id: "pptConverter",
    title: "Image to PowerPoint Converter",
    description: "Make PPT slides from images",
    path: "/ImageTools/Image-to-PowerPoint-Converter",
    icon: pptIcon,
  },
  {
    id: "pdfConverter",
    title: "Image to PDF",
    description: "Convert images to images PDF files",
    path: "/ImageTools/Image-to-PDF",
    icon: pdfIcon,
  },
  {
    id: "jpgToPdf",
    title: "JPG to PDF",
    description: "Convert your JPG images to PDF file",
    path: "/PdfTools/Jpg-to-PDF",
    icon: pdfIcon,
  },
  {
    id: "webpToPdf",
    title: "WEBP to PDF",
    description: "Convert your WEBP images to PDF file",
    path: "/PdfTools/webp-to-PDF",
    icon: pdfIcon,
  },
  {
    id: "pngToPdf",
    title: "PNG to PDF",
    description: "Convert your PNG images to PDF file",
    path: "/PdfTools/png-to-PDF",
    icon: pdfIcon,
  },
  {
    id: "jpegToPdf",
    title: "JPEG to PDF",
    description: "Convert your JPEG images to PDF file",
    path: "/PdfTools/Jpeg-to-PDF",
    icon: pdfIcon,
  },
  {
    id: "imageTextExtractor",
    title: "Text Extractor from image",
    description: "Extract text from image",
    path: "/ImageTools/imageTextExtractor",
    icon: textIcon,
  },
  {
    id: "formatConverter",
    title: "PNG to WEBP converter",
    description: "Convert PNG files to WEBP",
    path: "/ImageTools/imageConverter",
    icon: image,
  },
  {
    id: "psdtosvg",
    title: "PSD to SVG converter",
    description: "Convert PSD files to SVG",
    path: "/ImageTools/PsdToSvg",
    icon: image,
  },
{
    id: "pdfToJpeg",
    title: "PDF to JPEG Converter",
    description: "Convert PDF files to JPEG",
    path: "/PdfTools/pdftojpeg",
    icon: image,
},
{
    id: "pdfToPng",
    title: "PDF to PNG Converter",
    description: "Convert PDF files to PNG",
    path: "/PdfTools/pdftopng",
    icon: image,
},
{
    id: "pdfToJpg",
    title: "PDF to JPG Converter",
    description: "Convert PDF files to JPG",
    path: "/PdfTools/pdftojpg",
    icon: image,
},
{
  id: "pdfToSvg",
  title: "PDF to SVG Converter",
  description: "Convert PDF files to SVG",
  path: "/PdfTools/pdftosvg",
  icon: image,
},
{
  id: "pdfToImages",
  title: "PDF to Images Converter",
  description: "Convert PDF files to Images",
  path: "/PdfTools/pdftoImage",
  icon: image,
},
{
    id: "pdfToWebp",
    title: "PDF to WEBP Converter",
    description: "Convert PDF files to WEBP",
    path: "/PdfTools/pdftowebp",
    icon: image,
},

  {
    id: "WebpToAvif",
    title: "Webp to Avif converter",
    description: "Convert Webp files to Avif",
    path: "/ImageTools/WebpToAvif",
    icon: image,
  },
  {
    id: "jpgToPng",
    title: "JPG to PNG Converter",
    description: "Convert JPG files to PNG",
    path: "/ImageTools/JpgToPng",
    icon: image,  
  },
  {
    id: "svgToPng",
    title: "SVG to PNG Converter",
    description: "Convert SVG files to PNG",
    path: "/ImageTools/SvgToPng",
    icon: image,  
  },
  {
    id: "jpegToPng",
    title: "JPEG to PNG Converter",
    description: "Convert JPEG files to PNG",
    path: "/ImageTools/JpegToPng",
    icon: image,  
  },
  {
    id: "jpegToWebp",
    title: "JPEG to WEBP Converter",
    description: "Convert JPEG files to WEBP",
    path: "/ImageTools/JpegToWebp",
    icon: image,  
  },
  {
    id: "jpgToGif",
    title: "JPG to GIF Converter",
    description: "Convert JPG files to GIF",
    path: "/ImageTools/JpgToGif",
    icon: image,  
  },
  {
    id: "pngToGif",
    title: "PNG to GIF Converter",
    description: "Convert PNG files to GIF",
    path: "/ImageTools/PngToGif",
    icon: image,  
  },
  {
    id: "gifToPng",
    title: "GIF to PNG Converter",
    description: "Convert GIF files to PNG",
    path: "/ImageTools/GifToPng",
    icon: image, 
  },
  {
    id: "gifToWebp",
    title: "GIF to WEBP Converter",
    description: "Convert GIF files to WEBP",
    path: "/ImageTools/GifToWebp",
    icon: image,  
  },
  {
    id: "gifToApng",
    title: "GIF to Apng Converter",
    description: "Convert GIF files to WEBP",
    path: "/ImageTools/GifToApng",
    icon: image,  
  },
  {
    id: "imageUpscaler",
    title: "Image Upscaler",
    description: "Upscale your images",
    path: "/ImageTools/imageUpscaler",
    icon: upscaleIcon,
  },
  {
    id: "BackGround",
    title: "Image BackGround Remover",
    description: "Remove your image background",
    path: "/ImageTools/BackGround",
    icon: bgRemoverIcon,
  },
  {
    id: "RoundImageCropper",
    title: "Profile Pcture maker",
    description: "Crop your images in round shape",
    path: "/ImageTools/RoundImageCropper",
    icon: crop2Icon,
  },
  {
    id: "tiffToJpg",
    title: "TIFF to JPG Converter",
    description: "Convert TIFF files to JPG",
    path: "/ImageTools/TiffToJpg",
    icon: image,
},
{
    id: "jpgToTiff",
    title: "JPG to TIFF Converter",
    description: "Convert JPG files to TIFF",
    path: "/ImageTools/JpgToTiff",
    icon: image,
},
{
    id: "jpgtosvg",
    title: "JPG to SVG Converter",
    description: "Convert JPG files to TIFF",
    path: "/ImageTools/JpgToSvg",
    icon: image,
},
{
    id: "pngToEps",
    title: "PNG to EPS Converter",
    description: "Convert PNG files to EPS",
    path: "/ImageTools/PngToEps",
    icon: image,
},
{
    id: "epsToPng",
    title: "EPS to PNG Converter",
    description: "Convert EPS files to PNG",
    path: "/ImageTools/EpsToPng",
    icon: image,
},
{
    id: "pngToTiff",
    title: "PNG to TIFF Converter",
    description: "Convert PNG files to TIFF",
    path: "/ImageTools/PngToTiff",
    icon: image,
},
{
  id: "VsdToJpg",
  title: "Vsd to Jpg Converter",
  description: "Convert Vsd files to Jpg",
  path: "/ImageTools/VsdToJpg",
  icon: image,
},
{
  id: "VsdxToJpg",
  title: "Vsdx to Jpg Converter",
  description: "Convert Vsdx files to Jpg",
  path: "/ImageTools/VsdxToJpg",
  icon: image,
},
{
  id: "pngToTiff",
  title: "PNG to TIFF Converter",
  description: "Convert PNG files to TIFF",
  path: "/ImageTools/PngToTiff",
  icon: image,
},
{
  id: "GifToMp4",
  title: "Gif to Mp4 Converter",
  description: "Convert PNG files to TIFF",
  path: "/ImageTools/GifToMp4",
  icon: image,
},

];

const ImageTools = (props) => {
  const { searchTerm, setSearchTerm } = useSearch();
    useEffect(() => {
    return () => {
      setSearchTerm("");
    };
  }, [setSearchTerm]);

  const location = useLocation(); 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const displayCards = searchTerm
    ? cards.filter((card) =>
        card.title.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : cards;

  return (<>
  <Helmet>
  <meta charSet="utf-8" />
  <title>Image Tools - Edit, Resize, and Enhance Images</title>
  <link rel="canonical" href="http://toolsbag.io/ImageTools" />
  <meta name="description" content="Use our powerful Image Tools to edit, resize, crop, and enhance your images online. Perfect for content creators, designers, and everyday users." />
  <meta name="keywords" content="image tools, online image editor, resize images, crop images, enhance images, image editing, image tools, online image editor, image editing, resize images, crop images, enhance images, photo editor, image resizer, online photo editor, image cropping tool, adjust image brightness, adjust image contrast, image sharpening tool, blur images online, photo filters, image effects, rotate images, flip images, adjust image size, image format converter, photo editing for social media, edit images for Instagram, photo editing for Facebook, photo editing for Twitter, image tools for content creators, image editing for designers, image editing for bloggers, image tools for photographers, professional image editing, basic image editing, advanced image editing, free online image tools, fast image editing, mobile image editor, compress images, reduce image size, online image compression, web image optimization, image editing for websites, crop images for profiles, crop images for banners, crop images for headers, crop images for thumbnails, image tools for YouTube, crop images for YouTube thumbnails, online image resize, image editing no download, cloud-based image editor, instant photo editor, bulk image editing, batch image resizing, convert images to black and white, photo editing for e-commerce, image editing for product photography, image tools for business, image tools for marketing, photo editor for flyers, photo editor for brochures, image tools for posters, image editing for advertising, image tools for branding, image tools for logo design, photo editor for events, photo editor for invitations, image editing for cards, image tools for holiday cards, image tools for greeting cards, image tools for wedding invites, image tools for birthday invites, image editing for personal use, image editor for beginners, easy image editor,
   simple image editor, quick image editing, photo editing without watermark, image tools without ads, high-quality image editing, online image cropper, online image enhancer, improve photo quality, fix blurry images, upscale images online, enlarge images without losing quality, image enhancer tool, make images clearer, adjust image hue, adjust image saturation, adjust image sharpness, reduce noise in images, denoise photos, retouch images, retouch portraits, photo touch-up, enhance skin tone in images, remove blemishes from photos, smooth skin in images, image tools for profile photos, edit profile photos, edit profile pictures for LinkedIn, edit profile pictures for Facebook, edit images for resumes, photo editing for CVs, image tools for professional use, image editor for documents, photo editor for real estate, photo editor for architecture, photo editor for floor plans, image tools for interior design, image tools for landscape design, image editor for social media posts, create memes online, add text to images, image tools for memes, create images with text, create image collages, make photo collages, online photo collage maker, image tools for scrapbooking, create photo albums online, photo editor for family photos, photo editor for vacation photos, photo editor for travel images, image tools for photographers, image tools for artists, image tools for illustrators, image tools for graphic designers, photo editor for creatives, online graphic design tool, free graphic design tool, photo editor for web designers, photo editor for digital marketers,
    image tools for SEO, edit images for blogs, edit images for websites, image editing for WordPress, resize images for WordPress, resize images for Shopify, resize images for WooCommerce, image tools for e-commerce, compress images for web, optimize images for fast loading, optimize images for mobile, create mobile-friendly images, online photo editing app, photo editor for mobile, image tools for Android, image tools for iPhone, image editing for iPad, image tools for tablets, photo editor for iOS, photo editor for Android, cloud photo editor, photo editor with cloud storage, save edited images online, photo editor with Google Drive, photo editor with Dropbox, online image editor for professionals, online image tools for students, online image tools for teachers, online image editor for presentations, photo editor for PowerPoint, resize images for PowerPoint, photo editor for Google Slides, edit photos for portfolios, edit images for online portfolios, edit images for Behance, edit images for Dribbble, photo editor for online galleries, photo editor for digital portfolios, image tools for creative professionals, online photo editor for business, image editing for startups, online photo editor for small businesses, create business cards online, image editor for brochures, online photo editing for newsletters, online image editor for email campaigns, online photo editor for magazines, edit images for publications, edit images for digital marketing, online photo editor for ads, photo editor for print materials, image tools for product catalogs, photo editor for digital advertising, create ad banners online, online photo editor for presentations, photo editor for slideshows, create slideshows online, image editor for videos, edit thumbnails for YouTube, image tools for video editing, online image tools for animation, online photo editor with special effects, online photo editor for filters, online image tools with overlays, and edit photos with frames.
" />
</Helmet>
    <div className="main1">
      <div className="pdf-body">
        <div className="root">
          <div className="top">
            <div className="h title_lg" style={{marginTop: '0px'}}>Image Tools</div>
          </div>
                <div class="home_content">
                  <p class="descr gray">Easy-to-use online Image tools</p>
                  <form
                    action="#"
                    class="search_group search_group--search"
                    id="searchToolsForm"
                  >
                    <input type="text" name="q" onChange={(e) => setSearchTerm(e.target.value)} placeholder="Search" />
                    <img
                      class="search"
                      src={search}
                    />
                  </form>
                </div>
          <div className="pro-container">
            {displayCards.map((card, index) => (
              <ToolCard
                key={index}
                id={card.id}
                title={card.title}
                description={card.description}
                path={card.path}
                icon={card.icon}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ImageTools;
