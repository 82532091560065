import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Loader from "./Loader1";
import 'jspdf-autotable';
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';
const ExcelToPdfConverter = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFileChange = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    setExcelFile(file);
    setPdf(null);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const convertToPdf = async () => {
    if (!excelFile) {
      alert('Please select an Excel file first.');
      return;
    }

    try {
      const excelData = await readExcelFile(excelFile);
      const generatedPdf = generatePdfContent(excelData);
      setPdf(generatedPdf);
    } catch (error) {
      console.error('Error converting Excel to PDF:', error);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const downloadPdf = () => {
    if (pdf) {
      pdf.save('converted.pdf');
    } else {
      alert('Please convert the Excel file to PDF first.');
    }
  };

  const readExcelFile = (file) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
          resolve(excelData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const generatePdfContent = (excelData) => {
    const pdf = new jsPDF();
    const columns = Object.keys(excelData[0]);
    const rows = excelData.map(row => Object.values(row));
    pdf.autoTable({
      head: [columns],
      body: rows,
    });
    return pdf;
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>
    Excel to PDF Converter - Convert Excel Files to PDF Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/PdfTools/ExcelToPdfConverter"
  />
  <meta
    name="description"
    content="Easily convert your Excel spreadsheets into PDF format online. Perfect for preserving formatting in reports, financial documents, and data sheets."
  />
  <meta
    name="keywords"
    content="excel to pdf, convert excel to pdf, xls to pdf converter, spreadsheet to pdf, convert xlsx to pdf, online excel to pdf converter, excel file to pdf, save excel as pdf, generate pdf from excel, PDF conversion for spreadsheets, export excel to pdf, quick excel to pdf tool, PDF format for excel sheets, online tools for pdf conversion, batch convert excel to pdf, secure pdf conversion, free excel to pdf service, desktop excel to pdf converter, PDF document creation from excel, create pdf from xls, excel spreadsheet management, efficient excel to pdf conversion, convert sheets to pdf, online document converter, easy excel to pdf tool, professional pdf from excel, compress excel to pdf, transform excel into pdf, online file conversion, print excel to pdf, convert financial reports to pdf, PDF conversion for business, digital document management, edit and convert excel to pdf, excel to PDF online free, user-friendly excel to pdf software, automate excel to pdf conversion,
     convert multiple excel files to pdf, keep formatting when converting excel to pdf, flexible excel to pdf options, PDF from excel for presentations, shareable pdf from excel, quick export from excel to pdf, PDF generation for spreadsheets, convert excel data to pdf, fast conversion of excel to pdf, convert workbook to pdf, PDF formatting for excel, high-quality pdf from excel, convert business documents to pdf, preserve formulas in pdf, secure your excel data in pdf, PDF tools for excel users, accessible excel to pdf conversion, PDF solutions for excel files, convert spreadsheets for reporting, reliable pdf generation from excel, convert excel charts to pdf, quick PDF file creation from excel, online conversion for excel spreadsheets, excel to PDF converter service, image quality in pdf from excel, create editable pdf from excel, formatted pdf output from excel, fast and easy pdf conversion, online document management for excel files, convert Excel worksheets to PDF, share PDF versions of excel documents, transform data into PDF format, PDF file conversion for analytics, preserve layout when converting to pdf, convert and save excel as pdf, portable document format from excel, generate printable pdf from excel, online pdf creation from spreadsheets, streamline pdf generation from excel, professional tools for excel to pdf conversion, create pdf for business reporting, automatic pdf creation from excel sheets, generate PDF for project tracking, easy-to-use pdf converter for excel, maintain data integrity when converting to pdf, digital transformation of excel files, seamless PDF output from excel, PDF generation with minimal effort, convert xlsm to pdf, enhance excel reports with PDF, upload excel and convert to pdf, create a PDF portfolio from excel, merge excel sheets into a PDF file, secure pdf for confidential excel data, convert excel tables to pdf format, excel to pdf document generator, fast conversion of spreadsheets to pdf."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Excel to PDF Converter</h1>
        <p className="mt_5">Convert your Excel files to PDF</p>
      </div>
      <div className='container'>
            <div className="uploader-container uploader-container--images">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {!excelFile ? (
                    <form className="file-upload-form">
                      <label htmlFor="file" className="file-upload-label">
                        <div className="file-upload-design">
                          <svg viewBox="0 0 640 512" height="1em">
                            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                          </svg>
                          <p>Drag and Drop</p>
                          <p>or</p>
                          <span className="browse-button">Browse file</span>
                        </div>
                        <input
                          id="file"
                          type="file"
                          accept=".xlsx, .xls"
                          onChange={handleFileChange}
                        />
                      </label>
                    </form>
                  ) : (
                    <div>
                      <div
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                        className="my-1"
                      >
                        {excelFile && excelFile.name}
                      </div>
                      <button className='normal-button my-2' onClick={convertToPdf}>Convert to PDF</button>
                      {pdf && <button className='buttonDownload mx-1' onClick={downloadPdf}>Download PDF</button>}
                      <button className='Reset-button' onClick={() => setExcelFile(null)}>Reset</button>
                    </div>
                  )}
                </>
              )}
            </div>
        <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to convert your Excel files to PDF format easily. Simply upload your Excel file, and click "Convert" to download your PDF document.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Convert Excel to PDF</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Upload the Excel file you want to convert to PDF.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Review the file information and ensure it's the correct file for conversion.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Convert" button to start the conversion process.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Once the conversion is complete, click "Download" to save your PDF document.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default ExcelToPdfConverter;
