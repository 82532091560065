import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Loader from "./Loader1";
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';

const ExcelSplitter = () => {
  const [file, setFile] = useState(null);
  const [startRow, setStartRow] = useState(1);
  const [endRow, setEndRow] = useState(1);
  const [loading, setLoading] = useState(false);
  const [maxRows, setMaxRows] = useState(1);
  const [downloadLink, setDownloadLink] = useState(null);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFileChange = (event) => {
    setLoading(true)
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      if (worksheet && worksheet['!ref']) {
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        setMaxRows(range.e.r + 1);
        setEndRow(range.e.r + 1);
      } else {
        alert('Invalid worksheet structure');
      }
    };
    reader.readAsArrayBuffer(selectedFile);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const handleStartRowChange = (event) => {
    setStartRow(parseInt(event.target.value, 10));
  };

  const handleEndRowChange = (event) => {
    setEndRow(parseInt(event.target.value, 10));
  };

  const splitExcel = () => {
    if (!file) {
      alert('Please select an Excel file first.');
      return;
    }
    setLoading(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      if (worksheet && worksheet['!ref']) {
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        const actualEndRow = Math.min(endRow, range.e.r + 1);
        if (startRow < 1 || startRow > actualEndRow) {
          alert('Invalid row range');
          return;
        }

        const newWorksheetData = [];
        for (let row = startRow - 1; row < actualEndRow; row++) {
          const newRowData = [];
          for (let col = range.s.c; col <= range.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            newRowData.push(worksheet[cellAddress]?.v || '');
          }
          newWorksheetData.push(newRowData);
        }

        const newWorkbook = XLSX.utils.book_new();
        const newWorksheet = XLSX.utils.aoa_to_sheet(newWorksheetData);
        XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, 'Sheet1');

        const wbout = XLSX.write(newWorkbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });
        const url = URL.createObjectURL(blob);
        setDownloadLink(url);
      } else {
        alert('Invalid worksheet structure');
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <>
    <Helmet>
  <meta charSet="utf-8" />
  <title>Excel Splitter - Split Excel Files Online</title>
  <link rel="canonical" href="http://toolsbag.io/PdfTools/ExcelSplitter" />
  <meta
    name="description"
    content="Easily split your Excel files into multiple smaller spreadsheets online. Perfect for managing large datasets and dividing workbooks into separate files."
  />
  <meta
    name="keywords"
    content="excel splitter, split excel file, divide excel sheets, split xlsx file, separate excel worksheets, online excel splitting tool, excel file divider, extract sheets from excel, separate data in excel, online spreadsheet splitter, manage large excel files, break excel into parts, excel workbook separation, split excel documents, free excel splitter, excel sheet extraction, cloud-based excel splitter, easy excel file division, fast excel splitting tool, split large excel spreadsheets, online tools for excel management, divide excel data, organize excel sheets, excel data extraction, workbook sectioning, online document management, excel sheet separator, quick excel splitting, efficient excel data handling, separate large spreadsheets, excel file organization tool, user-friendly excel splitter, excel page extraction, online excel document division, extract specific sheets from excel, manage excel file sizes, automate excel splitting, excel section separation, powerful excel splitter, online workbook extraction, split excel for collaboration, divide sheets for sharing, excel file management tools,
     advanced excel splitting solutions, split by sheet name in excel, online excel worksheet cutter, easy-to-use excel file splitter, extract necessary sheets, split multi-sheet excel, excel sheet manager, free online excel sectioning, excel management tool for teams, organize data in excel, efficient document splitting, online excel file tools, fast and reliable excel splitter, split excel for reporting, break excel into smaller files, secure excel sheet division, excel content extraction, separate data sets in excel, split excel for analysis, simple online excel separator, effective excel management tools, online worksheet division, quick file splitting for excel, customize excel sheet separation, divide workbook into separate files, online data management solutions, split excel files for emailing, excel file division service, automated sheet separation, separate sheets from larger workbooks, manage excel sheet organization, extract data from large workbooks, excel separation and extraction, online excel organization tools, easy excel sheet sectioning, cloud-based excel management, fast excel sheet extraction, create separate spreadsheets from excel, free tools for excel splitting, online workbook manager, optimize excel files, excel worksheet management, excel file processor, document separation for excel files, separate content for better organization, manage excel files easily, split excel to share data, extract data from multiple sheets, quick and efficient excel file management, excel document extraction tool, separate and manage data effectively, split excel by column, excel document organization, split by range in excel, organize workbooks into sections, easy online excel extractor, excel file organization solutions, effective management for excel data, fast separation of excel sheets, excel splitting for better workflow."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Excel Splitter</h1>
        <p className="mt_5">Split your Excel files</p>
      </div>
      <div className="container">
            <div className="uploader-container uploader-container--images">
              {loading ? (
                <Loader />
              ) : !file ? (
                <form className="file-upload-form">
                  <label htmlFor="file" className="file-upload-label">
                    <div className="file-upload-design">
                      <svg viewBox="0 0 640 512" height="1em">
                        <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                      </svg>
                      <p>Drag and Drop</p>
                      <p>or</p>
                      <span className="browse-button">Browse file</span>
                    </div>
                    <input
                      id="file"
                      accept=".xlsx, .xls"
                      type="file"
                      onChange={handleFileChange}
                    />
                  </label>
                </form>
              ) : (
                <>
                  <div
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                    className="my-1"
                  >
                    {file && file.name}
                  </div>
                  <div className="input-container">
                    <input
                      id="input-start"
                      required=""
                      type="number"
                      value={startRow}
                      onChange={handleStartRowChange}
                      className="form-control text-input"
                      min="1"
                      max={maxRows}
                    />
                    <label htmlFor="input-start" className="label">
                      Start Row Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <div className="input-container">
                    <input
                      id="input-end"
                      required=""
                      type="number"
                      value={endRow}
                      onChange={handleEndRowChange}
                      className="form-control text-input"
                      min="1"
                      max={maxRows}
                    />
                    <label htmlFor="input-end" className="label">
                      End Row Number:
                    </label>
                    <div className="underline"></div>
                  </div>
                  <div className="container">
                    <button className="normal-button " onClick={splitExcel}>
                      Split Excel
                    </button>
                    {downloadLink && (
                      <a href={downloadLink} download="split_excel.xlsx" className="buttonDownload mx-1">
                        Download Split Excel
                      </a>
                    )}
                    <button className='Reset-button' onClick={() => setFile(null)}>Reset</button>
                  </div>
                </>
              )}
            </div>
      </div>
      <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to split your Excel files into smaller sheets or separate files easily. Simply upload your Excel file, select the splitting option, and click "Split" to download your new files.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Split Your Excel File</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Upload the Excel file you wish to split.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Choose how you want to split the file: by rows, columns, or sheets.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Split" button to start the process.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Once the splitting is complete, click "Download" to save your new files.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default ExcelSplitter;
