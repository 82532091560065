import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import Loader from "./Loader1";
import { useLocation } from "react-router-dom"; 
import { Helmet } from 'react-helmet';
 
const CsvToExcelConverter = () => {
  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [conversionComplete, setConversionComplete] = useState(false);
  const [excelWorkbook, setExcelWorkbook] = useState(null);
 const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleFileChange = (e) => {
    setLoading(true);
    const file = e.target.files[0];
    setCsvFile(file);
    setConversionComplete(false); // Reset conversion status
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const convertToExcel = () => {
    setLoading(true)
    if (!csvFile) {
      alert('Please select a CSV file first.');
      return;
    }
    readCsvFile(csvFile, (csvData) => {
      const workbook = convertCsvToExcel(csvData);
      setExcelWorkbook(workbook);
      setConversionComplete(true); // Set conversion status to true
    });
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const readCsvFile = (file, callback) => {
    Papa.parse(file, {
      complete: (result) => {
        if (result && result.data) {
          callback(result.data);
        } else {
          alert('Error parsing CSV file.');
        }
      },
      header: true, // Treat the first row as header
      skipEmptyLines: true,
    });
  };

  const convertCsvToExcel = (csvData) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    return wb;
  };

  const downloadExcel = () => {
    if (excelWorkbook) {
      XLSX.writeFile(excelWorkbook, 'converted.xlsx');
    } else {
      alert('Please convert the CSV file to Excel first.');
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    CSV to Excel Converter - Convert CSV Files to Excel Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/PdfTools/CsvToExcelConverter"
  />
  <meta
    name="description"
    content="Easily convert your CSV files into Excel format online. Ideal for managing data, reports, and spreadsheets."
  />
  <meta
    name="keywords"
    content="csv to excel, convert csv to excel, csv to xls, csv to xlsx converter, convert csv to spreadsheet, online csv converter, csv file to excel, transform csv to excel, CSV to XLSX conversion, spreadsheet conversion tool, free csv to excel tool, easy csv to excel conversion, upload csv and convert, excel from csv files, batch convert csv to excel, CSV file management, online spreadsheet converter, quick csv to excel converter, data manipulation, CSV to spreadsheet format, CSV to Excel online free, generate excel from csv, export csv to excel, import csv into excel, format csv in excel, online tools for csv conversion, CSV data to excel, spreadsheet creation from csv, efficient csv to excel tool, CSV file editing, convert csv for reporting, csv to excel worksheet, extract csv data to excel, easy data transfer from csv to excel, CSV table to excel, manage data with excel, online file conversion, CSV to Excel converter software, desktop csv to excel converter, convert csv for analysis, CSV to XLS file, automate csv to excel conversion, data organization with excel, flexible csv conversion, online document converter, convert comma-separated values to excel, CSV files in excel format, fast csv to excel conversion, user-friendly csv to excel tool, convert csv spreadsheets, CSV to Excel editing tool, cloud-based csv to excel converter, open csv files in excel, manipulate csv data in excel, convert CSV to Excel spreadsheets easily, integrate CSV data into excel, CSV format for excel, convert csv for better data visualization, digital file conversion, structured data management, excel compatibility with csv, convert text files to excel, rapid csv to excel transformation, online file format conversion, comprehensive CSV to Excel tool."
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">CSV to Excel Converter</h1>
        <p className="mt_5">Convert your CSV files to Excel</p>
      </div>
      <div className="container">
        <div className="row justify-content-center mb-4">
          <div className="col-xl-10">
            <div className="uploader-container uploader-container--images">
              {loading ? (
                <Loader />
              ) : (
                <>
                  {!csvFile ? (
                    <form className="file-upload-form">
                      <label htmlFor="file" className="file-upload-label">
                        <div className="file-upload-design">
                          <svg viewBox="0 0 640 512" height="1em">
                            <path d="M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z"></path>
                          </svg>
                          <p>Drag and Drop</p>
                          <p>or</p>
                          <span className="browse-button">Browse file</span>
                        </div>
                        <input
                          id="file"
                          type="file"
                          accept=".csv"
                          onChange={handleFileChange}
                        />
                      </label>
                    </form>
                  ) : (
                    <div>
                      <div
                        style={{
                          textDecoration: "underline",
                          fontWeight: "bold",
                        }}
                        className="my-1"
                      >
                        {csvFile && csvFile.name}
                      </div>
                      <button className='normal-button my-2' onClick={convertToExcel}>Convert to Excel</button>
                      {conversionComplete && <button className='buttonDownload mx-1' onClick={downloadExcel}>Download Excel</button>}
                      <button className='Reset-button' onClick={() => setCsvFile(null)}>Reset</button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        
      </div>
      <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to convert your CSV files into Excel format quickly. Simply upload your CSV file, and click "Convert" to download your Excel document.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Convert CSV to Excel</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Upload the CSV file you wish to convert to Excel.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Review the file information to ensure it's the correct file for conversion.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Convert" button to initiate the conversion process.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Once the conversion is complete, click "Download" to save your Excel document.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default CsvToExcelConverter;
