import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import Loader from "./Loader";
import { useLocation } from "react-router-dom"; 
import { Helmet } from "react-helmet";
const messageStyle = {
  padding: "10px",
  borderRadius: "10px",
  maxWidth: "100%",
  marginBottom: "10px",
  alignSelf: "flex-start",
};
const toneOfScript = [
  "Professional",
  "Casual",
  "Witty",
  "Excited",
  "Bold",
  "Sarcastic",
  "Secretive",
  "Dramatic",
  "Grumpy",
];
const ToneOfVoiceChanger = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [tone, settone] = useState("Professional");
  const outputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/2000`;
  };
  const clickHandle = async () => {
    if (!input || !tone ) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    const wordCount = input.trim().split(/\s+/).length;
    if (wordCount < 5) {
      alert("Please enter at least 5 words for the topic.");
      return;
    }
    try {
      setIsLoading(true);
      const prompt = `Change the tone of these line: "${input}" having "${toneOfScript[tone]}" tone`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20; 
      const sentencesPerParagraph = 10; 
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * tone;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });

      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };
  const handleCopy = () => {
    if (messages.length > 0 && currentIndex !== -1) {
      const textToCopy = messages[currentIndex].content;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
          alert("Text copied to clipboard!");
        })
        .catch((error) => {
          console.error("Error copying text: ", error);
        });
    }
  };
  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    settone(2);
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };
  const handletoneChange = (event) => {
    const selectedTone = event.target.value;
    const toneIndex = toneOfScript.indexOf(selectedTone);
    settone(toneIndex !== -1 ? toneIndex : 0);
  };
  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <><Helmet>
    <meta charSet="utf-8" />
    <title>Tone of Voice Changer - Change the Tone of Your Text</title>
    <link rel="canonical" href="http://toolsbag.io/AiTools/ToneOfVoiceChanger" />
    <meta
      name="description"
      content="Easily change the tone of your text online. Perfect for adjusting the style of your writing to suit different audiences and contexts."
    />
    <meta
      name="keywords"
      content="tone of voice changer, change text tone, text tone adjuster, tone converter, online tone changer, adjust writing style, writing tool, change writing tone, text style converter, adapt tone for audience, customize text tone, professional tone writer, casual tone changer, formal tone adjuster, friendly tone creator, persuasive tone changer, empathetic tone converter, creative tone adjuster, writing tone tool, voice adjustment for writing, style guide for tone, improve writing tone, edit text tone online, tone modification tool, text voice changer, tone alignment for content, tone of voice adaptation, audience-specific writing, tone enhancement tool, versatile tone changer, refine text style, personalizing writing tone, tonal variation in writing, text audience targeting, tone matching for communication, writing clarity through tone, emotional tone adjustment, tone sensitivity in writing, tone flexibility for blogs, professional writing tone, casual writing tone, formal writing tone, friendly writing tone, assertive tone adjustment, subtle tone changes, tone of voice in marketing, adjusting tone for social media, tone modulation in copywriting, write with the right tone, tailor writing to audience, tone analysis tool, content tone evaluation, optimizing tone for engagement, voice and tone consistency, enhancing text through tone, tone in branding, nuanced tone adjustment, adjust language tone, articulate tone for effectiveness, tone refinement for clarity."
    />
  </Helmet>
  
    <div className="text-center">
    <h1 className="title_lg">Tone of Voice Changer in Text</h1>
    <p className="mt_5">Change the tone of voice in Text</p>
  </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Your content</h4>
              <p>What's your content for which you wanna change tone?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/2000
                  </span>
                </div>
                <textarea
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="enter your text"
                  rows="10"
                  cols="100"
                  maxLength="2000"
                  id="prompt"
                  className="form-control"
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="education_level">Tone of voice:</label>
                <select
                  className="form-control my-2"
                  value={toneOfScript[tone]}
                  onChange={handletoneChange}
                >
                  {toneOfScript.map((level, index) => (
                    <option key={index} value={level}>
                      {level}
                    </option>
                  ))}
                </select>
              </div>
              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled"  ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">AI Output</h4>
                  <p>Use the generated content as you please</p>
                </div>
              </div>
              <div className="form-group">
                <div data-v-4e037fd2="" className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          title=""
                          aria-label="Rewrite"
                          data-original-title="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "380px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                    <div className="loader-container">
                      <Loader />
                    </div>
                  ) : (messages.length > 0 && currentIndex !== -1 && (
                          <div style={messageStyle}>
                            {messages[currentIndex].content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button className="buttonDownload my-" onClick={handleDownload}
                    disabled={messages.length === 0}>Download</button>
                <button className="Btn" onClick={handleCopy}>
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
  <p className="tool-detail">
    This tool allows you to modify the tone of your text to better suit your audience or purpose. Whether you want to make your writing more formal, casual, friendly, or persuasive, simply enter your text and select the desired tone to see the transformation.
  </p>
  <div className="row align-items-center small_gutter">
    <div className="col-lg-5 mt_30">
      <h3 className="sub_title">STEP BY STEP</h3>
      <h2 className="title">How To Change the Tone of Your Text</h2>
      <p className="mt_25">Follow the steps below</p>
      <div className="step_item_box pt_10">
        <div className="step_item mt_30">
          <h4>Step 1</h4>
          <p className="mt_5">
            Enter the text you want to modify in the provided input field.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 2</h4>
          <p className="mt_5">
            Select the desired tone from the available options, such as formal, casual, friendly, or persuasive.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 3</h4>
          <p className="mt_5">
            Click the "Change Tone" button to transform your text according to the selected tone.
          </p>
        </div>
        <div className="step_item mt_30">
          <h4>Step 4</h4>
          <p className="mt_5">
            Review the modified text, make any additional edits if necessary, and use it as needed!
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default ToneOfVoiceChanger;
