import React, { useState, useEffect, useRef } from "react";
import { OpenAI } from "openai";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import Loader from "./Loader";
import { Helmet } from "react-helmet";
const Translater = () => {
  const apiKey = "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";
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [paragraphs, setParagraphs] = useState("");
  const outputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const handleChange = (event) => {
    const inputValue = event.target.value;
    setInput(inputValue);
    const characterCount = inputValue.length;
    document.getElementById(
      "character-counter"
    ).innerText = `${characterCount}/1000`;
  };
  const clickHandle = async () => {
    if (!input || !paragraphs) return;
    outputRef.current?.scrollIntoView({ behavior: "smooth" });
    try {
      setIsLoading(true);
      const prompt = `Translate this content: "${input}" in "${paragraphs}" language`;
      const openai = new OpenAI({
        apiKey,
        dangerouslyAllowBrowser: true,
      });
      const userMessage = { role: "user", content: prompt };
      const averageTokensPerSentence = 20;
      const sentencesPerParagraph = 10;
      const tokensPerParagraph =
        averageTokensPerSentence * sentencesPerParagraph;
      const totalTokens = tokensPerParagraph * paragraphs;
      const chatCompletion = await openai.chat.completions.create({
        messages: [...messages, userMessage],
        model: "gpt-3.5-turbo",
        max_tokens: totalTokens,
      });
      const aiMessage = {
        role: "assistant",
        content: chatCompletion.choices[0].message.content,
      };
      setMessages([...messages, aiMessage]);
      setCurrentIndex(messages.length);
    } catch (error) {
      console.error("Error fetching response:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownload = () => {
    const textToSave = messages.map((message) => message.content).join("\n");
    const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "generated_text.doc");
  };

  const regenerateText = () => {
    setMessages([]);
    setCurrentIndex(-1);
    setInput("");
    setParagraphs("");
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      clickHandle();
    }
  };

  const handleParagraphsChange = (event) => {
    setParagraphs(event.target.value);
  };

  const handleNext = () => {
    if (currentIndex < messages.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <>
   <Helmet>
  <meta charSet="utf-8" />
  <title>
    Language Translator - Translate Any Natural Language Online
  </title>
  <link
    rel="canonical"
    href="http://toolsbag.io/AiTools/Translater"
  />
  <meta
    name="description"
    content="Translate any natural language online. Perfect for translating text into multiple languages quickly and easily."
  />
  <meta
    name="keywords"
    content="language translator, translate text, text translation tool, online language translation, natural language translation, translate documents, multilingual translation, instant translation, text converter, translate languages, language translation tool, translate online, language converter, translation services, translate speech, accurate translation, text interpreter, translation software, translate phrases, language translation app, online translator, real-time translation, translation solutions, professional translation, language services, translate paragraphs, translate essays, free translation tool, document translation, translation accuracy, foreign language translation, translate conversations, quick translation, language translator app, multilingual communication, translate articles, user-friendly translator, translation API, automatic translation, translate words, text localization, effective translation, online language services, translate sentences, translate websites, cross-language communication, idiomatic translation, cultural translation, translate for travel, instant language translation, translate in context, interpretation services, translate jargon, specialized translation, community translation, translate websites for SEO, language learning aid, translate with ease, translator for social media, academic translation, translate for business, customer support translation, professional document translation, translate medical texts, user-generated translations, voice translation tool,
     visual translation, translate audio to text, idioms translation, community-driven translation, online dictionary, contextual translation, language pair translation, translate manually, interactive translation, peer-to-peer translation, customizable translation settings, user-friendly interface, secure translation, translate in real-time, assistive translation technology, collaborative translation, industry-specific translation, mobile translation app, desktop translation software, translation for e-commerce, global communication, translate emails, translate scripts, automatic language detection, translate for academic purposes, translation for marketing, instant feedback translation, secure document translation, enhance communication across languages, effective language conversion, non-English language translation, expert translation service, seamless translation experience, tech-enabled translation, translation management system, translate instructions, remote translation services, translation quality assurance, translate for content creators, enhance language understanding, versatile translation tool, corporate translation services, personalized translation solutions, community language support, translate slang, human-like translation, AI-powered translation, user-reviewed translations, localization for businesses, professional proofing services, translate for technical writing, instant language switch, translate social media posts, translation for hospitality, improve cross-border communication"
  />
</Helmet>


      <div className="text-center">
        <h1 className="title_lg">Language Translater</h1>
        <p className="mt_5">Translate your natural Language</p>
      </div>
      <div
        className="summarizer container"
        style={{ minHeight: "406px", display: "block" }}
      >
        <div className="row justify-content-center small_gutter">
          <div className="col-12 col-lg-4 col-xl-4">
            <div className="summarizer_item">
              <h4 className="title">Translate</h4>
              <p>What's your content to Translate ?</p>
              <div className="form-group">
                <div className="d-flex align-items-center justify-content-end">
                  <span id="character-counter" className="counter">
                    0/1000
                  </span>
                </div>
                <textarea
                  onChange={handleChange}
                  onKeyDown={handleKeyPress}
                  placeholder="Enter your data here"
                  rows="10"
                  cols="100"
                  maxLength="1000"
                  id="prompt"
                  className="form-control"
                ></textarea>
                <div className="invalid-feedback" style={{ display: "none" }}>
                  <span>Please enter at least 5 characters</span>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="number_answers">Language:</label>
                <input
                  type="text"
                  className="form-control my-2"
                  value={paragraphs}
                  onChange={handleParagraphsChange}
                  placeholder="e.g. Urdu, Turkish"
                />
              </div>

              <div className="summarizer_action">
                <button className="button" onClick={clickHandle}>
                  Generate
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-8">
            <div className="summarizer_item is-disabled" ref={outputRef}>
              <div className="summarizer_item-header">
                <div>
                  <h4 className="title">Generated Translation</h4>
                  <p>Use the generated content as you please</p>
                </div>
              </div>
              <div className="form-group">
                <div   className="summernote-container">
                  <div className="note-editor note-frame card">
                    <div className="note-toolbar card-header" role="toolbar">
                      <div className="note-btn-group btn-group note-mybutton">
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm note-btn-rewrite"
                          aria-label="Rewrite"
                          onClick={regenerateText}
                        >
                          Clear
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handlePrevious}
                          disabled={messages.length === 0 || currentIndex === 0}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          className="note-btn btn btn-light btn-sm"
                          onClick={handleNext}
                          disabled={
                            messages.length === 0 ||
                            currentIndex === messages.length - 1
                          }
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="note-editing-area">
                      <div
                        className="note-editable card-block"
                        role="textbox"
                        style={{
                          height: "370px",
                          maxHeight: "380px",
                          overflowY: "auto",
                        }}
                      >
                        {isLoading ? (
                          <div className="loader-container">
                            <Loader />
                          </div>
                        ) : (
                          messages.length > 0 &&
                          currentIndex !== -1 && (
                            <div className="p-3">
                              {messages[currentIndex].content}
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="summarizer_action">
                  <button
                    className="buttonDownload mt-1"
                    onClick={handleDownload}
                    disabled={messages.length === 0}
                  >
                    Download
                  </button>
                <button className="Btn">
                  <span className="text">Copy</span>
                  <span className="svgIcon">
                    <svg
                      fill="white"
                      viewBox="0 0 384 512"
                      height="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <p className="tool-detail mt-5">
  This tool helps you translate text between different languages with ease. Whether you need to translate a document, a message, or any piece of content, simply enter your text, select the desired language, and the tool will provide an accurate translation.
</p>
<div className="row align-items-center small_gutter">
  <div className="col-lg-5 mt_30">
    <h3 className="sub_title">STEP BY STEP</h3>
    <h2 className="title">How To Translate Your Text</h2>
    <p className="mt_25">Follow the steps below</p>
    <div className="step_item_box pt_10">
      <div className="step_item mt_30">
        <h4>Step 1</h4>
        <p className="mt_5">
          Enter the text you want to translate into the provided input field.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 2</h4>
        <p className="mt_5">
          Select the target language from the available options.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 3</h4>
        <p className="mt_5">
          Click the "Translate" button to receive the translated text.
        </p>
      </div>
      <div className="step_item mt_30">
        <h4>Step 4</h4>
        <p className="mt_5">
          Review the translation and copy it for your use. You can also refine the input and translate it again if needed!
        </p>
      </div>
    </div>
  </div>
</div>

      </div>
    </>
  );
};

export default Translater;
